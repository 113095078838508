import { Component, OnInit } from '@angular/core';
import { OperatorService } from '../services/operator.service';
import { HttpClient } from '@angular/common/http';
//import * as QuicksightEmbedding from 'amazon-quicksight-embedding-sdk';
const QuicksightEmbedding = require('amazon-quicksight-embedding-sdk');
import { CookieService } from 'ngx-cookie-service';

// import {
//   embedDashboard,
//   embedVisual,
//   embedSession,
//   embedQSearchBar,
// } from 'amazon-quicksight-embedding-sdk';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-quicksight',
  templateUrl: './quicksight.component.html',
  styleUrls: ['./quicksight.component.scss']
})

export class QuicksightComponent implements OnInit {
  dashboard: any;
  constructor(private os: OperatorService, private http: HttpClient, private cookieService: CookieService) { }

  ngOnInit(): void {
    //this.embedDashboard();
    // this.os.quicksightEmbedURL().subscribe((response: any) => {
    //   console.log("QUICKSIGHT PAGE")
    // },
    //   (error) => {
    //     console.log("Error occured: " + error);
    //   })
    //this.GetDashboardURL();
  }

  //public async GetDashboardURL() {
    // const url = await this.http.get("https://x8sud1mred.execute-api.ap-southeast-1.amazonaws.com/test/qsembed").toPromise()
    // this.Dashboard(url);

    // this.os.quicksightEmbedURL().subscribe((response: any) => {
    //   console.log("QUICKSIGHT PAGE");
    //   const url = response;
    //   console.log("url", url)
    //   this.Dashboard(url);
    // },
    //   (error) => {
    //     console.log("Error occured: " + error);
    //   })


 // }

  // public Dashboard(embeddedURL: any) {
  //   var containerDiv = document.getElementById("dashboardContainer");
  //   var options = {
  //     url: embeddedURL,
  //     container: containerDiv,
  //     scrolling: "yes",
  //     height: "850px",
  //     width: "100%"
  //   };
  //   this.dashboard = QuicksightEmbedding.embedDashboard(options);
  // }

  // dashboard: any;

  //  embedDashboard() {
  //     var containerDiv = document.getElementById("embeddingContainer");
  //     var options = {
  //         // replace this dummy url with the one generated via embedding API
  //         url: "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/dashboardId?isauthcode=true&identityprovider=quicksight&code=authcode",  
  //         container: containerDiv,
  //         scrolling: "no",
  //         height: "700px",
  //         iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit,
  //                                           // to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
  //         width: "1000px",
  //         footerPaddingEnabled: true,
  //         sheetId: 'YOUR_SHEETID', // use this option to specify initial sheet id to load for the embedded dashboard
  //         sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls
  //         undoRedoDisabled: false, // use this option to disable undo and redo buttons
  //         resetDisabled: false // use this option to disable reset button
  //     };
  //this.dashboard = QuickSightEmbedding.embedDashboard(options);
  // }



}
