import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { DataUploadService } from '../services/data-upload.service'
import { FileApprovalService } from '../services/file-approval.service'
import { OperatorService } from '../services/operator.service';
//import * as moment from 'moment';
import * as moment from "moment-timezone";
@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.scss']
})
export class DataUploadComponent implements OnInit, OnDestroy {
  records: [] = [];
  @ViewChild('csvReader') csvReader: any;
  public display: boolean = false;
  public isValidFile: boolean = false;
  public errorMessage: any = '';
  public errorMessage1: any = '';
  public successs: any = '';
  public formData: any;
  public spiner: boolean = false;
  public selectedObj: {} = {};
  public droppedFile: any;
  public isActive: boolean = false;
  public inprogress: boolean = false;
  public csvData: any;
  constructor(
    private fileUploadService: DataUploadService,
    private fileApprovalService: FileApprovalService,
    private os: OperatorService
  ) {
    this.os.getCurrentUser();
  }

  ngOnInit(): void {

  }
  ngOnDestroy(): void {
  }
  onPress() {
    this.display = !this.display;
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = false;
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    let files = event.dataTransfer.files;
    if (files.length > 0) {
      this.droppedFile = files
    }
    //console.log('this.droppedFile', this.droppedFile)
    this.isActive = false;
    this.fileValidation(event)
  }

  onSelectedFile(event: any) {
    if (event.target.files.length > 0) {
      this.droppedFile = event.target.files;
      // console.log('this.droppedFile =>', this.droppedFile)
    }
    this.fileValidation(event)
  }
  deleteFile() {
    if (this.droppedFile.length > 0) {
      this.droppedFile = [];
      this.droppedFile = undefined;
      this.fileReset();
      //console.log('delete files', this.droppedFile)
    }
  }
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      this.csvData = reader.result;
      reader.onerror = error => reject(error);
    });
  }
  uploadFile() {
    this.inprogress = true;
    if (this.droppedFile && this.droppedFile.length > 0) {
      let file: File = this.droppedFile.item(0);
      //console.log(file.name);
      //console.log(file.size);
      var myFormData = new FormData();
      myFormData.append('file', file, file.name);

      this.fileUploadService.fileUpload(myFormData).subscribe(
        (response) => {
          //console.log("file uploaded: ", response);
          this.inprogress = false;
          this.isValidFile = true;
          this.errorMessage1 = '';
          this.errorMessage = "Upload Success!";
          this.successs = "The file will be processed once Approved";
          let currentUser = localStorage.getItem('currentUser');
          let currentUserEmailid = localStorage.getItem('currentUserEmailid')
          this.selectedObj = {
            "FILE_NAME": response,
            "STATUS": 'Pending',
            "UPLOADED_BY": currentUser,
            "UPLOADED_USR_EMAILID": currentUserEmailid,
            "APPROVED_BY": '',
            //"UPLOADED_DTTM": moment(new Date()).format("DD-MMM-YYYY HH:mm:ss"),
            "UPLOADED_DTTM": moment().tz("Asia/Singapore").format("DD-MMM-YYYY HH:mm:ss"),
            "USER_EMAILID": currentUserEmailid
          }
          //console.log("UPLOADED_BY", currentUser +''+ currentUserEmailid)
          //console.log("this.selectedObj", this.selectedObj)
          this.os.sendUploadFileEmailNotification(this.selectedObj, 'uploadFile').subscribe((response) => {
            //console.log("response", response)
          }, (error) => {
            console.log("Error occured: " + error);
          })
          this.updateDetails(this.selectedObj)
        },
        (error) => {
          console.log("Error occured: " + error);
          this.inprogress = false;
          this.isValidFile = false;
          this.errorMessage1 = '';
          this.errorMessage = "Error at upload CSV File On S3 Bucket";
          this.successs = "Please try after some time";
        }
      )
      this.fileReset();
      //}
    }
  }

  format(date: any | null): string {

    let DELIMITER = '-';
    let months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    //console.log(date);
    //console.log(moment(date));
    //return date ? date.day + DELIMITER + months[date.month-1] + DELIMITER + date.year : '';
    var Y = date.substring(6, 10);
    var D = date.substring(0, 2);
    var M = date.substring(3, 5);
    return date ? D + DELIMITER + months[M - 1] + DELIMITER + Y : '';
  }

  fileValidation($event: any): void {
    let flag = 0;
    //let files = $event.srcElement.files;
    let allowedFiles = [".csv"];
    let path
    for (let i = 0; i < this.droppedFile.length; i++) {
      //console.log ("Block statement execution no." + i);
      path = this.droppedFile[i].name
    }
    let regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$");
    let matchfilename = new RegExp("^CLR_Strike_Data_\\d{8}.csv$")
    //let matchfilename = new RegExp ("^CLR_Strike_data_\\d{8}_\\d{1,10}.csv$")
    if (!regex.test(path.toLowerCase())) {
      this.fileReset();
      this.errorMessage = "Please upload files having extensions .CSV"
      this.successs = '';
      this.errorMessage1 = '';
      this.isValidFile = false;
      return
    }
    else if (!matchfilename.test(path)) {
      this.fileReset();
      this.errorMessage = "You have uploaded a file with the incorrect name format.";
      //console.log ("this.droppedFile", path);
      this.successs = 'Please change the file name format to CLR_Strike_Data_DDMMYYYY.';
      this.errorMessage1 = '';
      this.isValidFile = false;
      return
    }
    ///content validation start
    else if (regex.test(path.toLowerCase()) && matchfilename.test(path)) {
      //let input = $event.target;  
      let reader = new FileReader();
      //reader.readAsText(input.files[0]);
      reader.readAsText(this.droppedFile.item(0));
      reader.onload = (event: any) => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        //let csvRecordsArray = (csvData).split('\n');
        if (csvRecordsArray.length == 0) {
          flag = 1;
          return
        } else {
          //var rows = event.target.result.split("\n");
          for (var i = 0; i < (csvRecordsArray.length - 1); i++) {
            var cells = csvRecordsArray[i].split(",");
            if (cells.length != 6) {
              flag = 1;
              // break;
              this.errorMessage = "Incorrect File Fields";
              this.errorMessage1 = "Please upload file with the correct no of fields";
              this.successs = '';
              break;
            }
            //else if(csvRecordsArray[i] == ("") || csvRecordsArray[1] == ("")){
            else if (cells.includes('')) {
              flag = 1;
              // break;
              this.errorMessage = "File Contains Empty Fields";
              this.errorMessage1 = "Please upload file with the correct content";
              this.successs = '';
              break;
            }


            var cells1 = csvRecordsArray[0].split(",");
            for (var j = 0; j <= cells1.length; j++) {
             // console.log("cells1", cells1)
              if (cells1[0] != "UserHPCountryCode") {
                flag = 1
                //break;
              }
              else if (cells1[1] != "UserHPNum") {
                flag = 1
                //break;
              }
              else if (cells1[2] != "CompositionSumConvictionDate(DD/MM/YYYY)") {
                flag = 1
                //break;
              }
              else if (cells1[3] != "NoticeNo") {
                flag = 1
                //break;
              }
              else if (cells1[4] != "VehicleNo") {
                flag = 1
                //break;
              }
              else if (cells1[5] != "DeviceType") {
                flag = 1
                //break;
              }
              if (flag == 1) {
                this.errorMessage = "You have uploaded a file with incorrect field positions.";
                this.errorMessage1 = "Please upload file with the correct field position.";
                this.successs = '';
                //break;
              }
            }

          }
        }
        if (flag == 1) {
          //window.alert("ERROR-2");
          // this.errorMessage = "Invalid File";
          // this.errorMessage1 = "Please upload correct file";
          this.successs = "";
          this.fileReset();
          this.isValidFile = false;
        }
        else {
          console.log("FILE VALIDATED");
          this.isValidFile = true;
        }

      }
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.errorMessage = undefined;
  }
  closePopup(event: any) {
    this.errorMessage = undefined;
    event.stopPropagation()
  }
  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.droppedFile = [];
    this.droppedFile = undefined;
  }

  updateDetails(obj: object) {
    this.fileApprovalService.updateFiles(obj).subscribe(
      (response) => {
        //console.log("VALUE updated: ", response);
        //this.showPopup = true;
        //this.GetDetails()
      },
      (error) => {
        console.log("Error occured: " + error);
      }
    )
  }



}