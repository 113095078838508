import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataUploadComponent } from './data-upload/data-upload.component';
import { FileHistoryComponent } from './file-history/file-history.component';
import { DataUploadService } from './services/data-upload.service';
import { FileHistoryService } from './services/file-history.service';
import { OperatorManagementComponent } from './operator-management/operator-management.component';
import { ViewOperatorComponent } from './view-operator/view-operator.component';
import { EditOperatorComponent } from './edit-operator/edit-operator.component';
import { AddOperatorComponent } from './add-operator/add-operator.component';
import { MaincomponentComponent } from './maincomponent/maincomponent.component';
//import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './Error/error.component';
import { HeaderComponent } from './header/header.component';
import { LeftPanelComponent } from './left-panel/left-panel.component';
import { DatePipe } from '@angular/common';
import { FileApprovalComponent } from './file-approval/file-approval.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { MatTableModule } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service'; 
import { BnNgIdleService } from 'bn-ng-idle';
import { QuicksightComponent } from './quicksight/quicksight.component';
import { UserBanSearchComponent } from './user-ban-search/user-ban-search.component';


@NgModule({
  declarations: [
    AppComponent,
    DataUploadComponent,
    FileHistoryComponent,
    OperatorManagementComponent,
    ViewOperatorComponent,
    AddOperatorComponent,
    EditOperatorComponent,
    MaincomponentComponent,
   // LoginComponent,
    ErrorComponent,
    HeaderComponent,
    LeftPanelComponent,
    FileApprovalComponent,
    QuicksightComponent,
    UserBanSearchComponent
  ],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    Ng2SearchPipeModule, 
    Ng2OrderModule,
    NgxPaginationModule,
    BackButtonDisableModule.forRoot(),
    MatTableModule,
],
  exports: [FormsModule, ReactiveFormsModule],
  providers: [DataUploadService,FileHistoryService, DatePipe, CookieService, BnNgIdleService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
