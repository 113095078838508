<div class="operator-container">
    <div class="row">
          <div class="col-12" style="text-align: left;">
            <label class="header">Operator List</label><br>
            <button class="btn btn btn-success" routerLink="../addOperator">+ ADD OPERATOR</button>
            &nbsp;&nbsp;<button class="btn btn btn-success" routerLink="../viewOperator">VIEW OPERATOR LIST</button>
            &nbsp;&nbsp;<button class="btn btn btn-success">EXPORT OPERATOR LIST</button>
          </div>

          <div class="col-12">
          <table class="table">
              <thead class="thead-light">
                <tr>
                       <th scope="col">OperatorID</th>
                        <th scope="col">Operator<br> ShortName</th>
                        <th scope="col">Contact<br> Person Name</th>
                        <th scope="col">Phone<br> No</th>
                        <th scope="col">Email<br> Address</th>
                        <th scope="col">Device <br>Type ID</th>
                        <!-- <th scope="col">No. of Devices<br>Registered</th> -->
                        <th scope="col">Device Type 1</th>
                        <th scope="col">Device Type 2</th>
                        <th scope="col">Device Type 3</th>
                        <th scope="col">Total Devices</th>
                        <th scope="col">Notification<br> Date</th>
                        <th scope="col">End<br> Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                        <!-- <th scope="col" *ngFor = "let col of columns">
                          {{col}}
                        </th> -->
                </tr>
              </thead>
              <tbody>
                  

              </tbody>
            </table>
          </div>
      </div>
  </div>
