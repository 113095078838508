<div class="container-fluid">
  <div class="row topspace1">
    <div class="col-12">
      <h5>Operator List Management</h5>
    </div>
  </div>
  <!-- <div class="operator-subheader">
  <label class="header">Operator List Management</label><br>
  </div> -->
  <div class="row topspace1 just-cont">
    <div class="flex">
      <button class="btn btn btn-success" routerLink="../addOperator">+ ADD OPERATOR</button>
      &nbsp;&nbsp;<button class="btn btn btn-success" (click)="exportToExcel()" *ngIf='operators.length'>EXPORT OPERATOR
        LIST</button>
    </div>
    <div class="flex-search">
      <div class="operator-search" *ngIf='operators.length'>
        <div class="maindivsearch">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input class="form-control" type="text" name="search" [(ngModel)]="searchText" (ngModelChange)="filterData()"
            autocomplete="off" class="right" placeholder="Search">
        </div>
      </div>
    </div>
  </div>
  <div class="operator-container">
    <div *ngIf="!spiner" class="row height-100">
      <div class="col-12 height-100" style="text-align: left;">




        <div class="col-12 tblcontentscroll height-100" *ngIf='operators.length'
          style="overflow-x: scroll; overflow-y: scroll;padding:0">
          <table class="table" id="excel-table">
            <thead class="thead-light">
              <col style="width: 60%;" />
              <col width="20px" />
              <col width="20px" />
              <tr>
                <th colspan="9" scope="col"></th>
                <th colspan="4" scope="col">REPRESENTATIVE 1</th>
                <th colspan="4" scope="col">REPRESENTATIVE 2</th>
                <th colspan="9" scope="col"></th>
              </tr>
              <tr>
                <th scope="col" style="white-space:nowrap; vertical-align: top;" (click)="sort('OPRID_NUM')">Operator
                  ID&nbsp; <i class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap; vertical-align: top;" (click)="sort('Status')">Status <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_UEN')">Unique Entity<br>Number&nbsp; <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_NAME')">Operator<br> Short Name <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_DESC')">Operator<br> Long Name <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_CP_NAME')">Contact<br> Person Name <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_REG_ADDR')">Registered<br> Business
                  Address <i class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_SG_ADDR')">Singapore<br> Address <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_PHONE_NUM')">Phone<br> Number <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_EMAIL_ID')">Email<br> Address <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REP1_CP_NAME')">Contact<br> Person Name <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REP1_SG_ADDR')">Singapore<br> Address <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REP1_PHONE_NUM')">Phone<br> Number <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REP1_EMAIL_ID')">Email<br> Address <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REP2_CP_NAME')">Contact<br> Person Name <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REP2_SG_ADDR')">Singapore<br> Address <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REP2_PHONE_NUM')">Phone<br> Number <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REP2_EMAIL_ID')">Email<br> Address <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REG_NO_OF_DEVICE_CLASS1')">Device Type 1 <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REG_NO_OF_DEVICE_CLASS2')">Device Type 2 <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('REG_NO_OF_DEVICE_CLASS3')">Device Type 3 <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap">Total<br> Devices</th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_START_DT')">Notification<br> Date <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('OPR_END_DT')">Operator End<br> Date <i
                    class="fa fa-sort"></i></th>
                <th scope="col" style="white-space:nowrap" (click)="sort('COMPLIANCE_DECLARATION')">Compliance<br>
                  Declaration <i class="fa fa-sort"></i></th>

                <th scope="col" style="vertical-align: top;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of filteredData |orderBy: key: reverse | paginate: config">
                <td>
                  {{row.OPRID_NUM}}
                </td>
                <td>
                  <span *ngIf="row.STATUS != 'In-Active'" class="badge bg-success"
                    [ngClass]="{'bg-success': row.STATUS === 'Active' }">{{row.STATUS}}</span>
                  <!-- <span *ngIf="row.STATUS != 'Active'" else class="badge bg-success"
                    [ngClass]="{'bg-success': row.STATUS === 'Active', 'bg-danger':row.STATUS === 'In-Active' }">{{row.STATUS}}</span> -->
                  <span *ngIf="row.STATUS != 'Active'" Class="badge bg-danger"
                    [ngClass]="{'bg-success': row.STATUS === 'In-Active' }">{{row.STATUS}}</span>
                </td>
                <td>{{row.OPR_UEN}}</td>
                <td>
                  {{row.OPR_NAME}}
                </td>
                <td>
                  {{row.OPR_DESC}}
                </td>
                <td>
                  {{row.OPR_CP_NAME}}
                </td>
                <td>{{row.OPR_REG_ADDR}}</td>
                <td>{{row.OPR_SG_ADDR}}</td>
                <td>
                  <!-- <span ng-if="row.OPR_PHONE_NUM != null">{{row.OPR_PHONE_NUM}}</span> -->
                  {{row.OPR_PHONE_NUM}}
                </td>
                <td>
                  {{row.OPR_EMAIL_ID}}
                </td>
                <td>{{row.REP1_CP_NAME}}</td>
                <td>{{row.REP1_SG_ADDR}}</td>
                <td>{{row.REP1_PHONE_NUM}}</td>
                <td>{{row.REP1_EMAIL_ID}}</td>

                <td>{{row.REP2_CP_NAME}}</td>
                <td>{{row.REP2_SG_ADDR}}</td>
                <td>{{row.REP2_PHONE_NUM}}</td>
                <td>{{row.REP2_EMAIL_ID}}</td>
                <td>
                  {{row.REG_NO_OF_DEVICE_CLASS1}}
                </td>
                <td>
                  {{row.REG_NO_OF_DEVICE_CLASS2}}
                </td>
                <td>
                  {{row.REG_NO_OF_DEVICE_CLASS3}}
                </td>
                <td>{{getTotalnumberofDevices(row.REG_NO_OF_DEVICE_CLASS1,row.REG_NO_OF_DEVICE_CLASS2,
                  row.REG_NO_OF_DEVICE_CLASS3)}}

                <td>{{row.OPR_START_DT}}</td>
                <td>{{row.OPR_END_DT}}</td>

                <td>{{row.COMPLIANCE_DECLARATION}}</td>


                <td>
                  <div class="dropdownbox" *ngIf="showActiveDiv[row.OPRID_NUM]">
                    <ul>
                      <li (click)="editOperatorForm(row.OPRID_NUM)">Edit</li>
                      <li (click)="deletOperatorConfirmation(row.OPRID_NUM)">Delete</li>
                    </ul>
                  </div>
                  <span>
                    <a (click)="onSelect($event, row.OPRID_NUM)" herf="javascript:void(0)">
                      <i class="fa fa-circle" aria-hidden="true"></i>
                      <i class="fa fa-circle" aria-hidden="true"></i>
                      <i class="fa fa-circle" aria-hidden="true"></i>
                    </a>
                  </span>
                  <!-- <div class="dropdownbox" *ngIf="showActiveDiv[row.OPRID_NUM]">
                      <ul >
                             <li (click)="editOperatorForm(row.OPRID_NUM)">Edit</li>
                             <li (click)="deletOperatorConfirmation(row.OPRID_NUM)">Delete</li>
                          </ul>
                      </div>  -->
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
      <div class="row alignpg" *ngIf='operators.length'>
        <span style="padding-left: 30px;padding-top: 5px;"> Total Rows: {{ getRecordCounts(null)}} </span>
        <pagination-controls (pageChange)="pageChange($event)" class="my-pagination"></pagination-controls>
        <!-- <pagination-controls ></pagination-controls>  -->
      </div>
      <!-- to be reomved after export table using the above -->
      <div style="display:none">
        <!-- *ngIf="false" -->
        <table class="table" id="export-excel-table">
          <thead class="thead-light">
            <col style="width: 60%;" />
            <col width="20px" />
            <col width="20px" />
            <tr>
              <th colspan="9" scope="col"></th>
              <th colspan="4" scope="col">REPRESENTATIVE 1</th>
              <th colspan="4" scope="col">REPRESENTATIVE 2</th>
              <th colspan="9" scope="col"></th>
            </tr>
            <tr>
              <th scope="col" style="white-space:nowrap; vertical-align: top;" (click)="sort('OPRID_NUM')">
                OperatorID&nbsp; <i class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_UEN')">Unique Entity<br>Number&nbsp; <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_NAME')">Operator<br> Short Name <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_DESC')">Operator<br> Long Name <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_CP_NAME')">Contact<br> Person Name <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_REG_ADDR')">Registered<br> Business Address
                <i class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_SG_ADDR')">Singapore<br> Address <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_PHONE_NUM')">Phone<br> Number <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_EMAIL_ID')">Email<br> Address <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REP1_CP_NAME')">Contact<br> Person Name <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REP1_SG_ADDR')">Singapore<br> Address <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REP1_PHONE_NUM')">Phone<br> Number <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REP1_EMAIL_ID')">Email<br> Address <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REP2_CP_NAME')">Contact<br> Person Name <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REP2_SG_ADDR')">Singapore<br> Address <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REP2_PHONE_NUM')">Phone<br> Number <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REP2_EMAIL_ID')">Email<br> Address <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REG_NO_OF_DEVICE_CLASS1')">Device Type<br>
                Class1 <i class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REG_NO_OF_DEVICE_CLASS2')">Device Type<br>
                Class2 <i class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('REG_NO_OF_DEVICE_CLASS3')">Device Type<br>
                Class3 <i class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap">Total<br> Devices</th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_START_DT')">Notification<br> Date <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('OPR_END_DT')">Operator End<br> Date <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap" (click)="sort('COMPLIANCE_DECLARATION')">Compliance<br>
                Declaration <i class="fa fa-sort"></i></th>
              <th scope="col" style="white-space:nowrap; vertical-align: top;" (click)="sort('Status')">Status <i
                  class="fa fa-sort"></i></th>
              <th scope="col" style="vertical-align: top;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of operators | filter:searchText ">
              <td>
                {{row.OPRID_NUM}}
              </td>
              <td>{{row.OPR_UEN}}</td>
              <td>
                {{row.OPR_NAME}}
              </td>
              <td>
                {{row.OPR_DESC}}
              </td>
              <td>
                {{row.OPR_CP_NAME}}
              </td>
              <td>{{row.OPR_REG_ADDR}}</td>
              <!-- <td style="white-space:nowrap">{{row.OPR_SG_ADDR}}</td> -->
              <td>{{row.OPR_SG_ADDR}}</td>
              <td>{{row.OPR_PHONE_NUM}}</td>
              <td>
                {{row.OPR_EMAIL_ID}}
              </td>
              <td>{{row.REP1_CP_NAME}}</td>
              <td>{{row.REP1_SG_ADDR}}</td>
              <td>{{row.REP1_PHONE_NUM}}</td>
              <td>{{row.REP1_EMAIL_ID}}</td>

              <td>{{row.REP2_CP_NAME}}</td>
              <td>{{row.REP2_SG_ADDR}}</td>
              <td>{{row.REP2_PHONE_NUM}}</td>
              <td>{{row.REP2_EMAIL_ID}}</td>
              <td>
                {{row.REG_NO_OF_DEVICE_CLASS1}}
              </td>
              <td>
                {{row.REG_NO_OF_DEVICE_CLASS2}}
              </td>
              <td>
                {{row.REG_NO_OF_DEVICE_CLASS3}}
              </td>
              <td>{{getTotalnumberofDevices(row.REG_NO_OF_DEVICE_CLASS1,row.REG_NO_OF_DEVICE_CLASS2,
                row.REG_NO_OF_DEVICE_CLASS3)}}
              <td>{{row.OPR_START_DT}}</td>
              <td>{{row.OPR_END_DT}}</td>
              <td>{{row.COMPLIANCE_DECLARATION}}</td>
              <td><span class="badge bg-success">Active</span></td>
              <!-- <td *ngIf="!compareDates(row.OPR_START_DT,row.OPR_END_DT)"><span class="badge bg-danger">In-active</span></td> -->
              <td>
                <div class="dropdownbox">
                  <ul>
                    <li (click)="editOperatorForm(row.OPRID_NUM)">Edit</li>
                    <li (click)="deletOperatorConfirmation(row.OPRID_NUM)">Delete</li>
                  </ul>
                </div>
                <span>
                  <a (click)="onSelect($event, row.OPRID_NUM)" herf="javascript:void(0)">
                    <i class="fa fa-circle" aria-hidden="true"></i>
                    <i class="fa fa-circle" aria-hidden="true"></i>
                    <i class="fa fa-circle" aria-hidden="true"></i>
                  </a>
                </span>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
    <div *ngIf="spiner" class="text-center topspace">Loading...</div>
  </div>
  <div *ngIf="!operators" class="errormsg">
    <span>{{errormsg}}</span>
  </div>
  <div *ngIf="PopupModelDispay" class="custom-model">
    <div class="model">
      <div class="model-body">
        <!-- <p>{{promptmessage}}</p> -->
        <p>Operator deleted successfully!</p>
        <button value="Okbutton1" class="btn btn btn-success" (click)="closepopup()">OK</button>
      </div>
    </div>
    <div class="model-background"></div>
  </div>


  <div *ngIf="PopupModelDispayDelete" class="custom-model">
    <div class="model">
      <div class="model-body">
        <p>Are you sure you want to Delete?</p>
        <button value="Yesbutton1" class="btn btn btn-success" (click)="DeletOKConfirm()">Yes</button>&nbsp;&nbsp;&nbsp;
        <button value="Nobutton1" class="btn btn btn-success" (click)="closepopupdelete()">No</button>
      </div>
    </div>
    <div class="model-background"></div>
  </div>


  <div *ngIf="ErrorsPopupModelDispay" class="custom-model">
    <div class="model">
      <div class="model-body">
        <div class="modal-header">
          <h5 class="modal-title">Session Expired</h5>
        </div>
        <p class="promptMsg">{{promptmessage}}</p>
        <div class="buttonclass"><button class="buttonprimary" (click)="closeErrPopup()">OK</button>&nbsp;&nbsp;&nbsp;
        </div>
      </div>
    </div>
    <div class="model-background"></div>
  </div>