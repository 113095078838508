
<div class="container">
    <div class="row">
        <div class="col-12">
            
                    <div class="btndiv text-center">
                        <button class="btn btn btn-success" routerLink="../dataUpload">Strike Data Upload</button>
                        <span>&nbsp;</span>
                        <button class="btn btn btn-success fright" routerLink="../viewOperator">Operator List Management</button>
                    </div>
        </div>
    </div>
    </div>


