import { Component, OnInit, HostListener } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { map, filter, switchMap } from 'rxjs/operators';


import { Operators } from '../Operators';
import { OperatorService } from '../services/operator.service';
//import * as data from '../../../db.json'; 

import { DatePipe } from '@angular/common'
import * as xlsx from 'xlsx';
import { FormGroup } from '@angular/forms';
import { ElementArrayFinder } from 'protractor';


@Component({
  selector: 'app-view-operator',
  templateUrl: './view-operator.component.html',
  styleUrls: ['./view-operator.component.scss']
})
export class ViewOperatorComponent implements OnInit {

  // displayedColumns: string[] = ['created', 'state', 'number', 'title'];

  constructor(private ps: OperatorService, private route: ActivatedRoute, private _router: Router, public datepipe: DatePipe) {
    this.ps.getCurrentUser();
    this.config = {
      currentPage: 1,
      itemsPerPage: 5,
    };

    // console.log(this.route.queryParamMap)
    this.route.queryParamMap
      .pipe(map((params: any) => params.get('page')))
      .subscribe((page: any) => (this.config.currentPage = page));
  }

  //for search
  OperatorID: any;
  config: any;
  OprID: any;
  searchText = '';
  operators: Operators[] = [];
  filteredData: Operators[] = [];
  currentPage: number = 1;
  p: number = 1;
  PopupModelDispay: boolean = false;
  PopupModelDispayDelete: boolean = false;
  spiner: boolean = false;
  promptmessage: string = "";
  oprDeleted: any;
  detectInactiveOperator: any;
  DeleteConfirmation: number = 0;
  ErrorsPopupModelDispay: boolean = false;

  //for action 3 dots
  public showActiveDiv: { [key: number]: boolean } = {};

  today: any;
  time: any;
  errormsg = '';

  ngOnInit(): void {
    //this.spiner = true;

    this.getallOperators();
    //this.spiner = false;

  }

  sessionRenewal() {
    this.ps.sessionTokenRenewal().subscribe((res: any) => {
      if (res && res.serviceResponseHeader.statusCode == 'REF-TOK-SUC-200') {
        console.log("session renewal respose", res)
        this.getallOperators();
      }
      else {
        console.log("Session timeout / LOGOUT 1");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    }, (error) => {
      console.log("Error occured: " + error);
      console.log("SESSION TIMEOUT / LOGOUT 2")
      this.ErrorsPopupModelDispay = true;
      this.promptmessage = "Session has expired, Please re-login";
    })
  }

  getallOperators() {
    this.spiner = true;
    this.ps.getOperators().subscribe((response) => {
      this.operators = response;
      this.filteredData = response;
      //console.log(this.operators)
      if (this.operators != null) {
        this.operators.sort(function (a: any, b: any) {
          return <any>new Date(a.UPDATED_DTTM ? a.UPDATED_DTTM : a.CREATED_DTTM) - <any>new Date(b.UPDATED_DTTM ? b.UPDATED_DTTM : b.CREATED_DTTM);
        });
        this.spiner = false;
      }
      else {
        this.spiner = false;
        this.errormsg = "No Strike Data Found";
      }
    },
      (error) => {
        console.log("Error occured: " + error);
        if (error.error.serviceResponseHeader && (error.error.serviceResponseHeader.statusCode == 'UNAUTHORIZED-ERR' || error.error.serviceResponseHeader.statusCode == 'VAL-TOK-ERR-105')) {
          this.sessionRenewal();
        }
        else {
          console.log("Session timeout / LOGOUTT");
          this.ErrorsPopupModelDispay = true;
          this.promptmessage = "Session has expired, Please re-login";
        }
      })
  }

  editOperatorForm(OperatorID: string) {
    this._router.navigate(['../../editOperator', OperatorID]);
  }

  deletOperatorConfirmation(OperatorID: string) {
    this.OprID = OperatorID;
    this.PopupModelDispayDelete = true;

  }

  getDeletedOperator(oprID: any) {
    this.ps.getOperatorEdit(oprID).subscribe((response) => {
      //console.log("response response response", response);
      this.oprDeleted = response[0];
      this.deletOperator();
    }, (error) => {
      console.log("Error occured: " + error);
    })
  }

  deletOperator() {
    this.PopupModelDispayDelete = false;
    this.ps.DeleteOperator(this.OprID).subscribe((response) => {
      if (response == "success") {
        this.operators = response;
        this.promptmessage = "Operator deleted successfully!";
        this.PopupModelDispay = true;
        this.ps.getOperators();

        this.ps.sendOprEmailNotification(this.oprDeleted, 'delete').subscribe((response) => {
          //console.log("response", response)
        }, (error) => {
          console.log("Error occured: " + error);
        })
      }
      else if (response.code == "PROTOCOL_ENQUEUE_AFTER_QUIT" || response.code == "ER_PARSE_ERROR") {
        // this.operators = response;
        this.promptmessage = "Internal Error Please try later...";
        this.PopupModelDispay = true;
        this.ps.getOperators();
      }
    }, (error) => {
      console.log("Error occured: " + error);
    }
    )
  }

  getTotalnumberofDevices(dev1: string, dev2: string, dev3: string) {
    return (dev1 + dev2 + dev3);
  }

  //for sort
  key: string = '';
  reverse: boolean = false;
  sort(key: any) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  public getRecordCounts(flag: any) {
    if (flag === null) {
      return this.operators.length;
    } else {
      return '';
    }
  }

  fileName = 'CLR_Operator_List.xlsx';

  exportToExcel() {
    this.today = new Date();
    this.time = this.today.getDate() + "_" + this.today.getHours() + "_" + this.today.getMinutes() + "_" + this.today.getSeconds();
    let element = (<HTMLTableElement>document.getElementById("export-excel-table"));
    if (element.rows.length > 2) {
      const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);
      //delete tatus and action in excel
      for (let i = 1; i <= element.rows.length; i++) {
        delete (ws['Y' + i]);
        delete (ws['Z' + i]);
      }

      // delete(ws['AA1']); delete(ws['AA2']); delete(ws['AA3']); delete(ws['AA4']);
      /* generate workbook and add the worksheet */
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Operator List');
      /* save to file */
      xlsx.writeFile(wb, this.fileName);
    }
    else {
      console.log("no data in table....")
    }
  }

  // DateconversionforUI(pDate: any)
  //  {
  //    if (pDate != "0000-00-00")
  //    {
  //        return this.datepipe.transform(pDate, 'dd/MM/yyyy');
  //    }
  //   else
  //   {
  //     return ("31/12/9999");
  //   }
  //  }

  //   compareDates(startdate:any | null, enddate: any | null){
  //     var todayDate :any | null;
  //     todayDate = new Date(); 
  //     var latest_date : any ;
  //     latest_date = this.datepipe.transform(todayDate, 'dd/MM/yyyy');

  //     var E_date :any;
  //     E_date = this.datepipe.transform(enddate, 'dd/MM/yyyy');

  //     let S_date = new Date(startdate);// let cur_Date = new Date(todayDate);

  //     if (S_date > todayDate)
  //     {
  //       return false;
  //     }
  //     else
  //     {
  //         if (E_date == "0000-00-00")
  //            return true;
  //         if (E_date > latest_date)
  //            return true;
  //         else
  //             return false;
  //     }

  //  }

  closepopup() {
    this.PopupModelDispay = false;
    this.getallOperators();
  }

  closepopupdelete() {
    this.PopupModelDispayDelete = false;
  }


  closeErrPopup() {
    this.ErrorsPopupModelDispay = false;
    localStorage.clear();
    window.location.href = this.ps.getDslMonitoringUrl();
  }

  DeletOKConfirm() {
    this.DeleteConfirmation = 1;
    this.getDeletedOperator(this.OprID);
  }

  onSelect(event: any, index: any) {
    this.showActiveDiv[index] = true;
    for (let i = 0; i < this.operators.length; i++) {
      let ind: any;
      ind = this.operators[i]["OPRID_NUM"];
      if (ind != index) {
        this.showActiveDiv[ind] = false;
      }
    }
  }

  format(date: any | null): string {

    let DELIMITER = '-';
    let months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    //console.log(date);
    //console.log(moment(date));
    //return date ? date.day + DELIMITER + months[date.month-1] + DELIMITER + date.year : '';
    var Y = date.substring(6, 10);
    var D = date.substring(0, 2);
    var M = date.substring(3, 5);
    return date ? D + DELIMITER + months[M - 1] + DELIMITER + Y : '';
  }

  filterData() {
    let filterValue = this.searchText.trim().toLowerCase();

    this.filteredData = this.operators.filter(item =>
      // console.log(Object.values(item))
      Object.values(item).some(value => {
        if (value !== null && value !== undefined) {
          return value.toString().toLowerCase().includes(filterValue)
        }
        return false;
      })
    );
    this.config.currentPage = 1;
    this.filteredData.sort(function (a: any, b: any) {
      return <any>new Date(a.UPDATED_DTTM ? a.UPDATED_DTTM : a.CREATED_DTTM) - <any>new Date(b.UPDATED_DTTM ? b.UPDATED_DTTM : b.CREATED_DTTM);
    });
    // console.log('filteredData', this.filteredData);
  }

  // (pageChange)="p= $event"
  pageChange(newPage: any) {
    // console.log("URL",newPage)
    this._router.navigate(['/viewOperator'],
      { queryParams: { page: newPage }, relativeTo: this.route, queryParamsHandling: 'merge', });
    this.config.currentPage = newPage;
    this.filterData();
  }

}
