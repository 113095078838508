import { Component, OnInit } from '@angular/core';
import { OperatorService } from '../services/operator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { unauthLogin, loginErrorCode } from '../Error/constants';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {
  promptmessage: string = "";
  currentUser: string = '';
  currentUserEmailid: string = '';
  currentUserRole = [];
  ErrorsPopupModelDispay: boolean = false;
  showStrike: boolean = false;
  selectedItem: any;
  unAuthLogin: boolean = false;

  constructor(private os: OperatorService, private router: Router, private titleService: Title) {
  }

  ngOnInit(): void {
    this.validateLoginSession();
  }

  validateLoginSession() {
    //this.currentUser = "clr-0002"
    this.os.validateSession().subscribe((response: any) => {
      console.log("left comp js", response)
      if (response && Object.keys(response.serviceResponseBody).length > 0) {
        if (typeof (Storage) !== "undefined") {
          this.currentUser = response.serviceResponseBody.userName || '';
          this.currentUserEmailid = response.serviceResponseBody.email || '';
          this.currentUserRole = response.serviceResponseBody.permission || '';
          if (this.currentUser.startsWith("CL-") || this.currentUser.startsWith("cl-")) {
            this.sideNavigation('userBanSearch');
            this.selectedItem = 'userBanSearch';
          } else {
            this.sideNavigation('mainComponent');
          }
        }
      }
      else {
        console.log("not Authorised.");
          if (
            response?.serviceResponseHeader?.statusCode ===
              loginErrorCode?.unAuthProd
          ) {
            console.log('unauth Login encountered');
            this.unAuthLogin = true;
            this.router.navigate(['/errorComponent'], {
              queryParams: {
                errorTitle: unauthLogin?.title,
                errorDecription: unauthLogin?.desc,
              },
            });
          } else {
            console.log('not Authorised.');
            this.ErrorsPopupModelDispay = true;
            this.promptmessage = "Session has expired, Please re-login";
          }
        }
      },
      (error) => {
        console.log("Error occured: " + error);
        if (error.error.serviceResponseHeader && (error.error.serviceResponseHeader.statusCode == 'UNAUTHORIZED-ERR' || error.error.serviceResponseHeader.statusCode == 'VAL-TOK-ERR-105')) {
          this.sessionRenewal();
        }
        else {
          console.log("Session timeout / LOGOUT");
          if (
            error?.error?.serviceResponseHeader?.statusCode ===
              loginErrorCode?.unAuthUAT 
          ) {
            console.log('unauth Login encountered');
            this.unAuthLogin = true;
            this.router.navigate(['/errorComponent'], {
              queryParams: {
                errorTitle: unauthLogin?.title,
                errorDecription: unauthLogin?.desc,
              },
            });
          } else {
            this.ErrorsPopupModelDispay = true;
            this.promptmessage = "Session has expired, Please re-login";
          }
        }
      }
    );
  }

  sessionRenewal() {
    this.os.sessionTokenRenewal().subscribe((res: any) => {
      if (res && res.serviceResponseHeader.statusCode == 'REF-TOK-SUC-200') {
        console.log("session renewal respose", res)
        this.validateLoginSession();
      }
      else {
        console.log("Session timeout / LOGOUT");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    }, (error) => {
      console.log("Error occured: " + error);
      this.ErrorsPopupModelDispay = true;
      this.promptmessage = "Session has expired, Please re-login";
    })
  }

  sideNavigation(path: any) {
    console.log("PATH", path)
    if (this.currentUser.startsWith("CL-") || this.currentUser.startsWith("cl-")) {
      this.showStrike = false;
      if (path == 'userBanSearch')
        this.router.navigate(['/userBanSearch'])
      if (path == '')
        this.router.navigate([''])
    } else {
      this.showStrike = true;
      if (path == 'mainComponent')
        this.router.navigate(['/mainComponent'])
      if (path == 'dataUpload')
        this.router.navigate(['/dataUpload'])
      if (path == 'fileapproval')
        this.router.navigate(['/fileapproval'])
      if (path == 'viewOperator')
        this.router.navigate(['/viewOperator'])
      if (path == 'userBanSearch')
        this.router.navigate(['/userBanSearch'])
      if (path == '')
        this.router.navigate([''])
    }
  }

  closepopup() {
    this.ErrorsPopupModelDispay = false;
    localStorage.clear();
    window.location.href = this.os.getDslMonitoringUrl();
  }

  highlight(item: string) {
    this.selectedItem = item;
    console.log('this.selectedItem', this.selectedItem)
  }

}
