import { Component, OnInit } from '@angular/core';
import { FileHistoryService } from '../services/file-history.service'
import { FileApprovalService } from '../services/file-approval.service'
import { OperatorService } from '../services/operator.service';

@Component({
  selector: 'app-file-history',
  templateUrl: './file-history.component.html',
  styleUrls: ['./file-history.component.scss']
})
export class FileHistoryComponent implements OnInit {
  public fileHistory: [] = [];
  public onError: any;
  public spiner: boolean = false;
  public searchText: any;
  public key: string = '';
  public reverse: boolean = false;
  public showActiveDiv: { [key: number]: boolean } = {};
  public selectedObj: {} = {};
  Downloadingspiner: boolean = false;
  public showPopup: boolean = false;
  public disableLink: boolean = true;
  isToggled = false;
  ErrorsPopupModelDispay: boolean = false;
  promptmessage: string = "";

  constructor(
    private fileApprovalService: FileApprovalService,
    private fileHistoryService: FileHistoryService,
    private os: OperatorService
  ) {
    this.os.getCurrentUser();
  }

  ngOnInit(): void {
    this.GetDetails();
    // this.key = 'UPLOADED_DTTM';
    // this.reverse = !this.reverse;
  }

  sort(key: any) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  sortByDate() {
    if (!this.isToggled) {
      this.fileHistory.sort(function (a: any, b: any) {
        return <any>new Date(a.UPLOADED_DTTM) - <any>new Date(b.UPLOADED_DTTM);
      });
    } else {
      this.fileHistory.sort(function (a: any, b: any) {
        return <any>new Date(b.UPLOADED_DTTM) - <any>new Date(a.UPLOADED_DTTM);
      });
    }
    this.isToggled = !this.isToggled;
  }

  onSelect(event: any, index: any) {
    this.showActiveDiv[index] = true;
    for (let i = 0; i <= this.fileHistory.length; i++) {
      let ind: any;
      if (this.fileHistory[i]) {
        ind = this.fileHistory[i]["FILE_NAME"];
      }
      if (ind != index) {
        this.showActiveDiv[ind] = false;
      }
    }
    event.stopPropagation();
  }

  download(event: any, selectedItem: any, index: number) {
    this.selectedObj = {
      "FILE_NAME": selectedItem.FILE_NAME
      //"STATUS": "Approved"
    }
    //console.log("Selected item obj ", this.selectedObj);
    this.downloadFile(selectedItem.FILE_NAME, selectedItem.UPLOADED_DTTM);
    console.log("Filedownloaded");
    this.showActiveDiv[index] = false;
  }

  downloadFile(obj: string, uploadeddate: string) {
    let anchor = '<a/>';
    this.fileApprovalService.S3downloadFile(obj, uploadeddate).subscribe(response => {
      if (response == "Not Exists") {
        console.info('no file downloaded');
        //  return false;
        this.Downloadingspiner = true;
      }
      else {
        this.Downloadingspiner = false;
        const a = document.createElement('a');
        var blob = new Blob([decodeURIComponent(encodeURI(response))], {
          type: "application/vnd.ms-excel;charset=utf-8;"
        });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = obj;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        // return true;
      }
    }, (error) => {
      console.log('Error downloading the file');
      // return false;
      this.Downloadingspiner = true;
    },
    );

    //this.Downloadingspiner = false;
  }

  closePopup(event: any) {
    this.showPopup = false;
    this.Downloadingspiner = false;
    event.stopPropagation()
    this.disableLink = true;
  }

  closeErrPopup() {
    this.ErrorsPopupModelDispay = false;
    localStorage.clear();
    window.location.href = this.os.getDslMonitoringUrl();
  }

  GetDetails() {
    let formData = new FormData();
    this.spiner = true;
    this.fileHistoryService.getAllFiles().subscribe((response) => {
      this.fileHistory = response;
      this.fileHistory.sort(function (a: any, b: any) {
        return <any>new Date(a.UPLOADED_DTTM) - <any>new Date(b.UPLOADED_DTTM);
      });
      this.spiner = false;
      //console.log("VALUE RECEIVED: ", response);
    }, (error) => {
      console.log(error);
      if (error.error.serviceResponseHeader && (error.error.serviceResponseHeader.statusCode == 'UNAUTHORIZED-ERR' || error.error.serviceResponseHeader.statusCode == 'VAL-TOK-ERR-105')) {
        this.sessionRenewal();
      }
      else {
        console.log("Session timeout / LOGOUT");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
      //this.onError(error);
    })
  }

  sessionRenewal() {
    this.os.sessionTokenRenewal().subscribe((res: any) => {
      if (res && res.serviceResponseHeader.statusCode == 'REF-TOK-SUC-200') {
        console.log("session renewal respose", res)
        this.GetDetails();
      }
      else {
        console.log("Session timeout / LOGOUT");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    }, (error) => {
      console.log("Error occured: " + error);
      console.log("SESSION TIMEOUT / LOGOUT")
      this.ErrorsPopupModelDispay = true;
      this.promptmessage = "Session has expired, Please re-login";
    })
  }
}
