import { Component, NgModule } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { OperatorService } from '../app/services/operator.service';
import { unauthLogin, loginErrorCode } from './Error/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {
  public showHeader: boolean = true;
  ErrorsPopupModelDispay: boolean = false;
  promptmessage: string = "";
  currentUser: string = '';
  currentUserEmailid: string = '';
  currentUserRole = [];
  unAuthLogin: boolean = false;

  router;
  constructor(private _router: Router, private bnIdle: BnNgIdleService, private os: OperatorService) {
    this.router = _router;
    this.validateLoginSession();

    this.bnIdle.startWatching(900).subscribe((res) => {
      if (res) {
        console.log("session expired");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    })
  }

  validateLoginSession() {
     //localStorage.setItem('currentUser', 'cl-0002');
    //this.currentUser = localStorage.getItem('currentUser') || '';
    this.os.validateSession().subscribe((response: any) => {
      console.log("app js", response)
      if (response && Object.keys(response.serviceResponseBody).length > 0) {
        if (typeof (Storage) !== "undefined") {
          this.currentUser = response.serviceResponseBody.userName || '';
          this.currentUserEmailid = response.serviceResponseBody.email || '';
          this.currentUserRole = response.serviceResponseBody.permission || '';
          localStorage.setItem("currentUser", this.currentUser);
          localStorage.setItem("currentUserRole", JSON.stringify(this.currentUserRole));
          localStorage.setItem("currentUserEmailid", this.currentUserEmailid);
          console.log("Details stored")
        }
      }
      else {
        console.log("not Authorised.");
          if (
            response?.serviceResponseHeader?.statusCode ===
            loginErrorCode?.unAuthProd
          ) {
            console.log('unauth Login encountered');
            this.unAuthLogin = true;
            this.router.navigate(['/errorComponent'], {
              queryParams: {
                errorTitle: unauthLogin?.title,
                errorDecription: unauthLogin?.desc,
              },
            });
          } else {
            this.ErrorsPopupModelDispay = true;
            this.promptmessage = "Session has expired, Please re-login";
          }
        }
      },
      (error) => {
        console.log("Error occured: " + error);
        if (error.error.serviceResponseHeader && (error.error.serviceResponseHeader.statusCode == 'UNAUTHORIZED-ERR' || error.error.serviceResponseHeader.statusCode == 'VAL-TOK-ERR-105')) {
          this.sessionRenewal();
        }
        else {
          console.log("Session timeout / LOGOUT");
          if (
            error.error.serviceResponseHeader.statusCode ===
              loginErrorCode?.unAuthUAT
          ) {
            console.log('unauth Login encountered');
            this.unAuthLogin = true;
            this.router.navigate(['/errorComponent'], {
              queryParams: {
                errorTitle: unauthLogin?.title,
                errorDecription: unauthLogin?.desc,
              },
            });
          } else {
            this.ErrorsPopupModelDispay = true;
            this.promptmessage = "Session has expired, Please re-login";
          }
        }
      }
    );
  }

  sessionRenewal() {
    this.os.sessionTokenRenewal().subscribe((res: any) => {
      if (res && res.serviceResponseHeader.statusCode == 'REF-TOK-SUC-200') {
        console.log("session renewal respose", res)
        this.validateLoginSession();
      }
      else {
        console.log("Session timeout / LOGOUT");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    }, (error) => {
      console.log("Error occured: " + error);
      this.ErrorsPopupModelDispay = true;
      this.promptmessage = "Session has expired, Please re-login";
    })
  }

  closepopup() {
    this.ErrorsPopupModelDispay = false;
    localStorage.clear();
    window.location.href = this.os.getDslMonitoringUrl();
  }

  modifyHeader() { // This method is called many times
    //console.log(this.router.url); // This prints a loot of routes on console
    if (this.router.url === '/') {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }
  // // title = 'File-Upload';
  // showHead: boolean = false;
  // ngOnInit() {
  // }

  // constructor (private router: Router) {

  //   this.router.events.subscribe((event: any) => {
  //     if (event instanceof NavigationStart) {
  //       if (event.url === '/loginComponent') {
  //         this.showHead = false;
  //       } else {
  //         this.showHead = true;
  //       }
  //     }
  //   });
  // }
}
