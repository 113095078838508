<div class="container">
    <div class="row">
        <div class="leftmain">
            <div class="left-header" *ngIf="!unAuthLogin">
                <img src="../../assets/images/lta-logo.png">
            </div>
            <!-- <div class="left-link">
                <a routerLink="dataUpload">Strike Data Upload</a>
                <a routerLink="fileapproval">Strike Data Approval</a>
                <a routerLink="viewOperator">Operator List Management</a>
                <a href="javascript:void(0)">QuickSight Report</a>
            </div> -->
            <div class="left-link" style="margin-top: 10%;"  *ngIf="!unAuthLogin">
                <div>
                    <span *ngIf="showStrike" (click)="sideNavigation('dataUpload');highlight('dataUpload')"
                        [ngClass]="{ 'highlight': 'dataUpload' === selectedItem }">Strike Data Upload</span>

                    <span *ngIf="showStrike" (click)="sideNavigation('fileapproval');highlight('fileapproval')"
                        [ngClass]="{ 'highlight': 'fileapproval' === selectedItem }">Strike Data Approval</span>

                    <span *ngIf="showStrike" (click)="sideNavigation('viewOperator');highlight('viewOperator')"
                        [ngClass]="{ 'highlight': 'viewOperator' === selectedItem }">Operator List Management</span>

                    <span (click)="sideNavigation('userBanSearch');highlight('userBanSearch')"
                        [ngClass]="{ 'highlight': 'userBanSearch' === selectedItem }">User Ban Search
                    </span>

                    <span><a class="linkCss"
                            href="https://onemotoring.lta.gov.sg/content/onemotoring/home/driving/MTT_cert_status.html"
                            target="_blank">MTT Search </a></span>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="ErrorsPopupModelDispay" class="custom-model">
    <div class="model">
        <div class="model-body">
            <div class="modal-header">
                <h5 class="modal-title">Session Expired</h5>
            </div>
            <p class="promptMsg">{{promptmessage}}</p>
            <div class="buttonclass"><button class="buttonprimary" (click)="closepopup()">OK</button>&nbsp;&nbsp;&nbsp;
            </div>
        </div>
    </div>
    <div class="model-background"></div>
</div>