<div class="container-fluid">
<div class="row topspace1">
  <div class="col-12">
      <h5>Add Operator</h5>
  </div>
</div>
<form [formGroup] ="operatorForm"  (ngSubmit)="AddOperator()">
<div class="operator-container"  style="overflow: auto;">
    <div class="row" >
      <!-- <label class="header">ADD OPERATOR</label> -->
         <div class="col-12" style="overflow: auto;">
          <table class="table table-borderless">
              <tbody>
                <tr>
                 <td class="form-group" [ngClass] = "{'has-error': operatorForm.get('OperatorID').errors && (operatorForm.get('OperatorID').touched || operatorForm.get('OperatorID').dirty)}"> 
                   <label title="This field accepts alphanumeric characters with – symbol, e.g. CL-0001." class="required"> Operator ID</label>
                  <input required type="text" maxlength="10" class="form-control" id="OperatorID" name="OperatorID"  formControlName="OperatorID" (keypress)="operatorID($event)" (blur)="checkidFormat()">
                  <span class="help-block" *ngIf="operatorForm.get('OperatorID').errors && (operatorForm.get('OperatorID').touched || operatorForm.get('OperatorID').dirty)">
                      <span style="color:red"  *ngIf = "operatorForm.get('OperatorID').errors.required">
                        OperatorID required.
                      </span>
                </span>
                <span style="color:red" class="help-block" *ngIf="checkOprIdFormat">Please enter valid operator ID</span>
                 </td>
                <td class="form-group" [ngClass] = "{'has-error': operatorForm.get('UniqueEntityNumber').errors && (operatorForm.get('UniqueEntityNumber').touched || operatorForm.get('UniqueEntityNumber').dirty)}">
                  <label title="This field accepts alphanumeric characters with – symbol, e.g. 202212345A." class="required">Operator's Unique Entity Number</label>
                  <input type="text" maxlength="10" class="form-control" id="UniqueEntityNumber" name="UniqueEntityNumber" formControlName="UniqueEntityNumber" (keypress)="CharsNumbersOnly($event)">
                  <span class="help-block" *ngIf="operatorForm.get('UniqueEntityNumber').errors && (operatorForm.get('UniqueEntityNumber').touched || operatorForm.get('UniqueEntityNumber').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('UniqueEntityNumber').errors.required">
                      Unique Entity Number required.
                    </span>
              </span>
                </td>
                <td>
                  <label class="required" title="This field accepts alphanumeric and special characters, e.g. ABCDEF.">Operator Short Name</label>
                  <input type="text" class="form-control" id="OperatorShortName" name="OperatorShortName" formControlName="OperatorShortName">
                  <span class="help-block" *ngIf="operatorForm.get('OperatorShortName').errors && (operatorForm.get('OperatorShortName').touched || operatorForm.get('OperatorShortName').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('OperatorShortName').errors.required">
                      Operator Name required.
                    </span>
              </span>
                </td>
                </tr>
                <tr>
                  <td> 
                    <label title="This field accepts alphanumeric and special characters, e.g. Abcdef Pte Ltd.">
                      Operator Long Name</label>
                   <input type="text" class="form-control"  name="OperatorLongName" formControlName="OperatorLongName">
                  </td>
                 <td>
                   <label title="This field accepts alphanumeric and special characters, e.g. John Lim @ Ah Bin.">
                     Operator - Contact Person Name</label>
                   <input type="text" class="form-control"  name="OprContactPersonName" formControlName="OprContactPersonName">
                 </td>
                 <td>
                   <label title="This field accepts alphanumeric and special characters, e.g. Blk 11 #01-01, Hampshire Road, Singapore 219428.">
                     Operator - Registered Business Address</label>
                   <input type="text" class="form-control"  name="OprRegisteredBusinessAddress" formControlName="OprRegisteredBusinessAddress">
                 </td>
                 </tr>
                 <tr>
                  <td> 
                    <label title="This field accepts alphanumeric and special characters, e.g. Blk 11 #01-01, Hampshire Road, Singapore 219428.">
                      Operator - Singapore Address</label>
                   <input type="text" class="form-control"  name="OprSingaporeAddress" formControlName="OprSingaporeAddress">
                  </td>
                 <td>
                   <label class="required" title="This field accepts + symbol and numbers, e.g. +65 91234567.">
                     Operator Phone Number</label>
                   <div class="row">
                     <div class="col-sm-2">
                      <input type="text"  placeholder="+65" style="width:60px;" maxlength="4" class="form-control" name="OprPhoneNumberCode"  name="OprPhoneNumberCode" formControlName="OprPhoneNumberCode" (keypress)="NumbersOnlyphone($event,operatorForm.get('OprPhoneNumberCode').value)">
                     </div>
                     <div class="col-sm-10">
                     <input type="text"  placeholder="12345678" class="form-control" maxlength="10" name="OprPhoneNumber"  name="OprPhoneNumber" formControlName="OprPhoneNumber" (keypress)="NumbersOnly($event)">
                    </div>
                   </div>
                   <span class="help-block" *ngIf="operatorForm.get('OprPhoneNumber').errors && (operatorForm.get('OprPhoneNumber').touched || operatorForm.get('OprPhoneNumber').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('OprPhoneNumber').errors.required">
                      Operator Phone Number required.
                    </span>
                 </span>
                 </td>
                 <td>
                   <label class="required"title="This field accepts alphanumeric and special characters, e.g. john_lim@lta.gov.sg.">
                     Operator Email ID</label>
                   <input type="email" email maxlength="50" class="form-control" id="OprEmailAddress"  name="OprEmailAddress" formControlName="OprEmailAddress">
                   <span class="help-block" *ngIf="operatorForm.get('OprEmailAddress').errors && (operatorForm.get('OprEmailAddress').touched || operatorForm.get('OprEmailAddress').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('OprEmailAddress').errors.required">
                      Operator Email Address required.
                    </span>
                    <span style="color:red"  *ngIf = "operatorForm.get('OprEmailAddress').errors.email">
                      Operator Email Address is invalid.
                    </span>
                  </span>
                 </td>
                 </tr>
                 <tr>
                  <td> 
                   
                  <label class="required" title="This field accepts only numbers, e.g. 0.">
                    Number of Devices Registered for Type 1</label>
                  <input required type="text"  maxlength="5" class="form-control"  name="DeviceTypeClass1" formControlName="DeviceTypeClass1" (keypress)="NumbersOnly($event)" (focusout)="totalDevicesCount($event)">
                  <span class="help-block" *ngIf="operatorForm.get('DeviceTypeClass1').errors && (operatorForm.get('DeviceTypeClass1').touched || operatorForm.get('DeviceTypeClass1').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('DeviceTypeClass1').errors.required">
                      Number of Devices Registered for Type 1 required.
                    </span>
                    </span>
                  </td>
                 
                  <td> 
                    <label class="required" title="This field accepts only numbers, e.g. 1.">
                      Number of Devices Registered for Type 2</label>
                   <input required type="text"  maxlength="5" class="form-control"  name="DeviceTypeClass2" formControlName="DeviceTypeClass2" (keypress)="NumbersOnly($event)" (focusout)="totalDevicesCount($event)">
                   <span class="help-block" *ngIf="operatorForm.get('DeviceTypeClass2').errors && (operatorForm.get('DeviceTypeClass2').touched || operatorForm.get('DeviceTypeClass2').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('DeviceTypeClass2').errors.required">
                      Number of Devices Registered for Type 2 required.
                    </span>
                    </span>
                  </td>
                  <td>
                    <label class="required" title="This field accepts only numbers, e.g. 2.">
                      Number of Devices Registered for Type 3</label>
                   <input required type="text"  maxlength="5" class="form-control"  name="DeviceTypeClass3" formControlName="DeviceTypeClass3" (keypress)="NumbersOnly($event)" (focusout)="totalDevicesCount($event)">
                   <span class="help-block" *ngIf="operatorForm.get('DeviceTypeClass3').errors && (operatorForm.get('DeviceTypeClass3').touched || operatorForm.get('DeviceTypeClass3').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('DeviceTypeClass3').errors.required">
                      Number of Devices Registered for Type 3 required.
                    </span>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>                    
                  <label>Total Number of Devices Registered</label>
                  <input readonly type="text" value="{{totalDeviceCount}}" maxlength="5" class="form-control"  name="TotalDevices" formControlName="TotalDevices" (keypress)="NumbersOnly($event)">
                  </td>
                </tr>

                  <tr>
                  <td style="width:25%"> 
                    <label class="required">Compliance Declaration</label><br>
                    <label for="Yes"><input type="radio" value="Yes" checked="checked" name="ComplianceDeclaration" formControlName="ComplianceDeclaration">&nbsp;Yes</label>
                    
                     &nbsp; &nbsp;
                    <label for="No"><input type="radio" value="No"  name="ComplianceDeclaration" formControlName="ComplianceDeclaration">&nbsp;No</label>
                    <br>
                    <span class="help-block" *ngIf="operatorForm.get('ComplianceDeclaration').errors && (operatorForm.get('ComplianceDeclaration').touched || operatorForm.get('ComplianceDeclaration').dirty)">
                      <span style="color:red"  *ngIf = "operatorForm.get('ComplianceDeclaration').errors.required">
                        Compliance Declaration required.
                      </span>
                </span>
                  </td>
             <td> 
              <label class="required">Operator Notification Date</label>
              <div class="row">
                <div class="col-sm-10 pr-0">
                      <input  type="text" id="NotificationDate" class="form-control" formControlName="NotificationDate" placeholder="dd-mmm-yyyy"  
                          #NotificationDate="ngbDatepicker" ngbDatepicker required readonly style="background-color: transparent;">
                </div>
                  <div class="col-sm-2 pl-0">
               <button  class="calButton"  (click)="NotificationDate.toggle()" type="button">
                <i class="fa fa-calendar" aria-hidden="true"></i>
               </button>
              </div>
            </div>
                    <span class="help-block" *ngIf="operatorForm.get('NotificationDate').errors && (operatorForm.get('NotificationDate').touched || operatorForm.get('NotificationDate').dirty)">
                        <span style="color:red"  *ngIf = "operatorForm.get('NotificationDate').errors.required">
                          Operator Notification Date required.
                        </span> 
                    </span>
             </td>
              <td>
                <!-- <div style="display: flex;"> -->
                  <label>Operator End Date 
                    <span style="color: #f32b2b">*Notification to Planet will be triggered 2 days before end date. Operator will be off-boarded on the next day.</span>
                  </label>
                <!-- <span style="color: #f32b2b">*Notification to Planet will be triggered 2 days before end date. Operator will be off-boarded on the next day.</span>  -->
                <!--</div>              -->
              <div class="row" style="flex-wrap: nowrap;">
                <div class="col-sm-9 pr-0">          
              <input  type="text" id="OperatorEndDate" class="form-control" formControlName="OperatorEndDate" placeholder="dd-mmm-yyyy"  
              #OperatorEndDate="ngbDatepicker" ngbDatepicker readonly style="background-color: transparent;">
            </div>
            <div class="col-sm-2 pl-0" style="padding-right: 0px;">
              <button  class="calButton"  (click)="OperatorEndDate.toggle()" type="button">
                <i class="fa fa-calendar" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-sm-1.5 pl-0">
              <button  class="calButton"  (click)="clearDate()" type="button">
                <i style="color: #3a3939;"class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
              <span class="help-block" *ngIf="dateValidation">  
                <span style="color:red"  *ngIf = "dateValidation">
                  Operator End Date should be greater than Notification Date.
                </span> 
              </span>
              </td> 
                </tr>
                 <tr>
                  <td colspan="3" class="subheader"> 
                    <label>Representative 1</label></td>
                 </tr>
                 <tr> 
                  <td> 
                    <label class="required" title="This field accepts alphanumeric and special characters, e.g. John Lim @ Ah Bin.">
                      Contact Person Name</label>
                   <input required type="text" class="form-control"  name="Rep1ContactPersonName" formControlName="Rep1ContactPersonName" >
                   <span class="help-block" *ngIf="operatorForm.get('Rep1ContactPersonName').errors && (operatorForm.get('Rep1ContactPersonName').touched || operatorForm.get('Rep1ContactPersonName').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('Rep1ContactPersonName').errors.required">
                      Representative 1 Contact person name required.
                    </span>
              </span>
                  </td>
                 <td>
                   <label class="required" title="This field accepts alphanumeric and special characters, e.g. Blk 11 #01-01, Hampshire Road, Singapore 219428.">
                     Singapore Address</label>
                   <input required type="text" class="form-control"  name="Rep1SingaporeAddress" formControlName="Rep1SingaporeAddress" >
                   <span class="help-block" *ngIf="operatorForm.get('Rep1SingaporeAddress').errors && (operatorForm.get('Rep1SingaporeAddress').touched || operatorForm.get('Rep1SingaporeAddress').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('Rep1SingaporeAddress').errors.required">
                      Representative 1 Singapore address required.
                    </span>
                  </span>
                 </td> 
                 <td>
                   <label class="required" title="This field accepts + symbol and numbers, e.g. +65 91234567.">
                     Phone Number</label>
                   
                   <div class="row">
                    <div class="col-sm-2">
                      <input required type="text" placeholder="+65" style="width:60px;" maxlength="4" class="form-control" name="Rep1PhoneNumberCode"  name="Rep1PhoneNumberCode" formControlName="Rep1PhoneNumberCode" (keypress)="NumbersOnlyphone($event,operatorForm.get('Rep1PhoneNumberCode').value)">
                    </div>
                    <div class="col-sm-10">
                      <input required type="text" placeholder="12345678" class="form-control" maxlength="10" id="Rep1PhoneNumber"  name="Rep1PhoneNumber" formControlName="Rep1PhoneNumber" (keypress)="NumbersOnly($event)">
                    </div>
                  </div>
                  <span class="help-block" *ngIf="operatorForm.get('Rep1PhoneNumber').errors && (operatorForm.get('Rep1PhoneNumber').touched || operatorForm.get('Rep1PhoneNumber').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('Rep1PhoneNumber').errors.required">
                      Representative 1 Phone Number required.
                    </span>
                 </span>
                 </td>
                 </tr>
                 <tr>
                  <td> 
                    <label class="required" title="This field accepts alphanumeric and special characters, e.g. john_lim@lta.gov.sg.">
                      Email ID</label>
                   <input required type="email" email maxlength="50" class="form-control"  name="Rep1EmailAddress" formControlName="Rep1EmailAddress">
                   <span class="help-block" *ngIf="operatorForm.get('Rep1EmailAddress').errors && (operatorForm.get('Rep1EmailAddress').touched || operatorForm.get('Rep1EmailAddress').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('Rep1EmailAddress').errors.required">
                      Representative 1 Email Address required.
                    </span>
                    <span style="color:red"  *ngIf = "operatorForm.get('Rep1EmailAddress').errors.email">
                      Representative 1 Email Address is invalid.
                    </span>
                  </span>
                  </td>
                 <td>
                   
                 </td>
                 <td>
                  
                 </td>
                 </tr>
                 <tr>
                  <td colspan="3" class="subheader"> 
                    <label>Representative 2</label></td>
                 </tr>
                 <tr>
                  <td> 
                    <label title="This field accepts alphanumeric and special characters, e.g. John Lim @ Ah Bin.">
                      Contact Person Name</label>
                   <input type="text" class="form-control"  name="Rep2ContactPersonName" formControlName="Rep2ContactPersonName" >
                  </td>
                 <td>
                   <label title="This field accepts alphanumeric and special characters, e.g. Blk 11 #01-01, Hampshire Road, Singapore 219428.">
                     Singapore Address</label>
                   <input type="text" class="form-control"  name="Rep2SingaporeAddress" formControlName="Rep2SingaporeAddress">
                 </td>
                 <td>
                <label title="This field accepts + symbol and numbers, e.g. +65 91234567.">
                  Phone Number</label>
                 <div class="row">
                  <div class="col-sm-2">
                    <input type="text"  placeholder="+65" style="width:60px;" maxlength="4" class="form-control" name="Rep2PhoneNumberCode"  name="Rep2PhoneNumberCode" formControlName="Rep2PhoneNumberCode" (keypress)="NumbersOnlyphone($event,operatorForm.get('Rep2PhoneNumberCode').value)">
                  </div>
                  <div class="col-sm-10">
                    <input type="text"  placeholder="12345678" class="form-control" maxlength="10" id="Rep2PhoneNumber"  name="Rep2PhoneNumber" formControlName="Rep2PhoneNumber" (keypress)="NumbersOnly($event)">
                  </div>
                </div>
                 </td>
                 </tr>
                 <tr>
                  <td> 
                    <label title="This field accepts alphanumeric and special characters, e.g. john_lim@lta.gov.sg.">
                      Email ID</label>
                   <input type="email" email  maxlength="50" class="form-control"  name="Rep2EmailAddress" formControlName="Rep2EmailAddress">
                   <span class="help-block" *ngIf="operatorForm.get('Rep2EmailAddress').errors && (operatorForm.get('Rep2EmailAddress').touched || operatorForm.get('Rep2EmailAddress').dirty)">
                    <span style="color:red"  *ngIf = "operatorForm.get('Rep2EmailAddress').errors.email">
                      Representative 2 Email Address is invalid.
                    </span>
                  </span>
                  </td>
                  
                  </tr>
                  <!-- <tr>
                    
                    <td></td>
                  </tr> -->
                  <!-- <tr>
                    <td>
                       <button class="btn btn btn-success" [disabled]="operatorForm.invalid" >Submit</button> &nbsp; &nbsp; 
                      <button class="btn btn btn-success" type="submit" >Submit</button> &nbsp; &nbsp;
                      <button class="btn btn btn-success" type="button" (click)="operatorForm.reset()">Reset</button>&nbsp; &nbsp;
                      <button class="btn btn btn-success" type="button" routerLink="../viewOperator">Cancel</button>&nbsp; &nbsp;
                     
                    </td>
                  </tr> -->
              </tbody>
            </table>
            <div class="col-md-12 text-left">
                <!-- <button class="btn btn btn-success" [disabled]="operatorForm.invalid" >Submit</button> &nbsp; &nbsp; -->
                <button class="btn btn btn-success" type="submit" >SUBMIT</button> &nbsp; &nbsp;
                <button class="btn btn btn-success" type="button" (click)="operatorForm.reset()">RESET</button>&nbsp; &nbsp;
                <button class="btn btn btn-success" type="button" routerLink="../viewOperator">CANCEL</button>&nbsp; &nbsp;
            </div>
          </div>
      </div>
</div>

</form>
</div>
<div *ngIf="PopupModelDispay" class="custom-model" data-keyboard="false" data-backdrop="static">
  <div class="model">
      <div class="model-body">
        <p><i class="fa fa-check-circle-o" aria-hidden="true"></i></p> 
          <p>{{promptmessage}}</p>
          <button class="btn btn btn-success" routerLink="../viewOperator" >OK</button>&nbsp;&nbsp;&nbsp;
      </div>
  </div>
  <div class="model-background"></div>
</div>

<div *ngIf="ErrorsPopupModelDispay" class="custom-model">
  <div class="model">
      <div class="model-body">
        <p><i class="fa fa-close" aria-hidden="true"></i></p> 
          <p>{{promptmessage}}</p>
          <button class="btn btn btn-success" (click) = "closepopup()" >OK</button>&nbsp;&nbsp;&nbsp;
      </div>
  </div>
  <div class="model-background"></div>
</div>

<div *ngIf="spiner" class="custom-model">
  <div class="model">
      <div class="model-body">
          <p>Adding Operator...</p>
          <!-- <button value="Yesbutton1" class="btn btn btn-success">OK</button>&nbsp;&nbsp;&nbsp; -->
      </div>
  </div>
  <div class="model-background"></div>
</div>