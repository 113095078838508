<div class="container-fluid">
    <div class="row topspace1">
        <div class="col-12">
            <h5>User Ban Search</h5>
        </div>
    </div>

    <div>
        <form [formGroup]="multipleSearchForm">
            <div class="history" style="margin-bottom: 10px;">
                <div class="topspace1">
                    <div class="container">
                        <div class="contentscroll">
                            <!-- <form [formGroup]="multipleSearchForm"> -->
                            <div formArrayName="banFormArray" *ngFor="let item of banFormArray.controls; let i = index"
                                class=" item ">
                                <ng-container [formGroupName]="i">
                                    <div class="row">
                                        <div class="form-group col-sm-2 countrycode">
                                            <input type="text" #search class="form-control countrycodeInput"
                                                formControlName="countryCode" id="countryCode" name="countryCode"
                                                maxlength="4" minlength="2" [pattern]="countryCodePattern"
                                                autocomplete="off">
                                            <span class="help-block validationCSS"
                                                *ngIf="banFormArray.controls[i].invalid && banFormArray.controls[i].touched  && banFormArray.controls[i].invalid">
                                                e.g:+65
                                            </span>
                                        </div>
                                        <div class="form-group col-sm-4 inputbox">
                                            <input type="text" #search class="form-control inputWidth" id="mobnum"
                                                name="mobnum" formControlName="mobnum" placeholder="e.g. 12345678"
                                                maxlength="30" (keypress)="NumbersOnly($event)"
                                                (input)="onPaste($event,i)" autocomplete="off">
                                            <span class="help-block">
                                                <span class="validationCSS"
                                                    *ngIf="banFormArray.controls[i].invalid && banFormArray.controls[i].touched && banFormArray.controls[i].value['mobnum']==''">
                                                    Please enter mobile number.
                                                </span>
                                                <span class="validationCSS"
                                                    *ngIf="banFormArray.controls[i].value['countryCode']==''">
                                                    Please enter valid country code.
                                                </span>
                                                <span class="validationCSS"
                                                    *ngIf="banFormArray.controls[i].value['countryCode'] !='' && banFormArray.controls[i].value['mobnum'] !='' && banFormArray.controls[i].invalid">
                                                    Please enter valid country code.
                                                </span>
                                            </span>
                                        </div>
                                        <div class="col">
                                            <button type="button" class="btn button"
                                                [ngClass]="{'btn-success': banFormArray.valid,'btn-disable': banFormArray.invalid}"
                                                *ngIf="banFormArray.controls.length === 1 || banFormArray.controls.length < 10"
                                                (click)="addInput(i)"><i class="fa fa-plus"
                                                    aria-hidden="true"></i></button>
                                            <button type="button" class="btn ml-1 deleteBtn"
                                                *ngIf="banFormArray.length > 1" (click)="deleteInput(i)"><i
                                                    class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="ErrorsPopupModelDispay" class="custom-model">
                                    <div class="model">
                                        <div class="model-body">
                                            <div class="modal-header">
                                                <h5 class="modal-title">{{promptTitle}}</h5>
                                            </div>
                                            <p class="promptMsg">{{promptmessage}}</p>
                                            <div class="buttonclass"><button class="buttonprimary"
                                                    (click)="closepopup()">OK</button>&nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                    <div class="model-background"></div>
                                </div>
                            </div>
                            <!-- <button type="submit" class="btn submit btn-success" [disabled]="multipleSearchForm.invalid"
                                (click)="onSubmit()">Submit</button> -->
                            <!-- </form> -->
                        </div>
                    </div>
                </div>
            </div>
            <span class="help-block validationCSS" style="font-size: 15px;" *ngIf="banFormArray.controls.length == 10">
                *Maximum limit reached.
            </span>
            <button type="submit" class="btn submit btn-success" (click)="onSubmit()">Submit</button>
        </form>
    </div>

    <!-- data table -->
    <div *ngIf="isLoading">
        <div class="d-flex justify-content-center" style="margin: 7rem;">
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div *ngIf="!underService">
        <div *ngIf="myArrayFiltered.length > 0 && !isLoading">
            <!-- common search -->
            <div class="topspace2">
                <div class="history-search">
                    <div class="row">
                        <div class="maindivsearch">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" class="right" placeholder="Search">
                        </div>
                    </div>
                </div>
            </div>
            <div class="history">
                <div class="row">
                    <div class="col-12"></div>
                    <div class="col-12 tblcontentscroll fixTableHead">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <!-- <th scope="col" (click)="sortByID()">Mobile Number &nbsp; <i class="fa fa-sort"></i></th> -->
                                    <!-- <th scope="col" (click)="sortByDate('BANSTART')">Ban Start Date &nbsp; <i class="fa fa-sort"></i></th> -->
                                    <th scope="col" (click)="sortByCD()">Country Code &nbsp; <i class="fa fa-sort"></i>
                                    </th>
                                    <th scope="col" (click)="sortByPH()">Mobile Number &nbsp; <i class="fa fa-sort"></i>
                                    </th>
                                    <th scope="col" (click)="sortByDate('BANEND')">Ban End Date &nbsp; <i
                                            class="fa fa-sort"></i></th>
                                    <th scope="col" (click)="sort('BANSTATUSIND')">Ban Status &nbsp; <i
                                            class="fa fa-sort"></i></th>
                                    <!-- <th scope="col" (click)="sort('OPR_TYPE')">OPR TYPE &nbsp; <i class="fa fa-sort"></i></th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let data of myArrayFiltered | filter:searchText | orderBy: key: reverse let i = index">
                                    <!-- [ngClass]="{'table-danger': data.BANSTATUSIND == 'NA'}"> -->
                                    <!-- <td>{{data.UNIQUE_ID['countryCode']+ ' ' +data.UNIQUE_ID['mobnum']}}</td> -->
                                    <!-- <td>{{data.BANSTART}}</td> -->
                                    <td>{{data.UNIQUE_ID.split(' ')[0]}}</td>
                                    <td>{{data.UNIQUE_ID.split(' ')[1]}}</td>
                                    <td>{{data.BANEND}}</td>
                                    <td>{{data.BANSTATUSIND}}</td>
                                    <!-- <td>{{data.OPR_TYPE}}</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="sessionErrorsPopupModelDispay" class="custom-model">
    <div class="model">
        <div class="model-body">
            <div class="modal-header">
                <h5 class="modal-title">Session Expired</h5>
            </div>
            <p class="promptMsg">{{promptmessage}}</p>
            <div class="buttonclass"><button class="buttonprimary"
                    (click)="closeErrPopup()">OK</button>&nbsp;&nbsp;&nbsp;
            </div>
        </div>
    </div>
    <div class="model-background"></div>
</div>

<div *ngIf="underService" class="underServiceMsg">
    <p> LTA Portal is currently under maintenance.<br>
        We apologise for any inconvenience caused. Please try again later or contact us at <span style="color: #307bcb">dso_class_licence@lta.gov.sg</span>
        for assistance.
    </p>
</div>