import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { OperatorService } from '../services/operator.service';
import { Location } from '@angular/common';
import { unauthLogin, loginErrorCode } from '../Error/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public expanded: boolean = false;
  ErrorsPopupModelDispay: boolean = false;
  promptmessage: string = "";
  currentUser: string = '';
  showHome: boolean = false;
  unAuthLogin: boolean = false;

  constructor(private router: Router, private os: OperatorService, private _location: Location) { }

  ngOnInit(): void {
    this.validateSession();
  }

  validateSession() {
    //this.currentUser = "clr-0002"
    this.os.validateSession().subscribe((response: any) => {
      console.log("header", response)
      if (response && Object.keys(response.serviceResponseBody).length > 0) {
        if (typeof (Storage) !== "undefined") {
          this.currentUser = response.serviceResponseBody.userName || '';
          if (this.currentUser.startsWith("CL-") || this.currentUser.startsWith("cl-")) {
            this.showHome = false;
          } else {
            this.showHome = true;
          }
        }
      }
      else {
        console.log("not Authorised.");
          if (
            response?.serviceResponseHeader?.statusCode ===
            loginErrorCode?.unAuthProd
          ) {
            console.log('unauth Login encountered');
            this.unAuthLogin = true;
            this.router.navigate(['/errorComponent'], {
              queryParams: {
                errorTitle: unauthLogin?.title,
                errorDecription: unauthLogin?.desc,
              },
            });
          } else {
            this.ErrorsPopupModelDispay = true;
            this.promptmessage = 'Session has expired, Please re-login';
          }
        }
      },
      (error) => {
        console.log("Error occured: " + error);
        if (error.error.serviceResponseHeader && (error.error.serviceResponseHeader.statusCode == 'UNAUTHORIZED-ERR' || error.error.serviceResponseHeader.statusCode == 'VAL-TOK-ERR-105')) {
          this.sessionRenewal('validatesession');
        }
        else {
          console.log("Session timeout / LOGOUT");
          if (
            error?.error?.serviceResponseHeader?.statusCode ===
              loginErrorCode?.unAuthUAT ||
            error?.error?.serviceResponseHeader?.statusCode ===
              loginErrorCode?.unAuthProd
          ) {
            console.log('unauth Login encountered');
            this.unAuthLogin = true;
            this.router.navigate(['/errorComponent'], {
              queryParams: {
                errorTitle: unauthLogin?.title,
                errorDecription: unauthLogin?.desc,
              },
            });
          } else {
            this.ErrorsPopupModelDispay = true;
            this.promptmessage = "Session has expired, Please re-login";
          }
        }
      }
    );
  }


  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.expanded = false;
  }
  toggle(event: any) {
    this.expanded = !this.expanded;
    event.stopPropagation()
  }

  changePassword() {
    this.os.validateSession().subscribe((response: any) => {
      if (response) {
        console.log("BACK Navigation")
        window.location.href = this.os.getDslMonitoringUrl() + '/change-password?backurl=true';
      }
      else {
        console.log("not Authorised.");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    },
      (error) => {
        console.log("Error occured: " + error);
        if (error.error.serviceResponseHeader && (error.error.serviceResponseHeader.statusCode == 'UNAUTHORIZED-ERR' || error.error.serviceResponseHeader.statusCode == 'VAL-TOK-ERR-105')) {
          this.sessionRenewal('changePassword');
        }
        else {
          console.log("Session timeout / LOGOUT");
          this.ErrorsPopupModelDispay = true;
          this.promptmessage = "Session has expired, Please re-login";
        }
      })
  }

  sessionRenewal(value: any) {
    this.os.sessionTokenRenewal().subscribe((res: any) => {
      if (res && res.serviceResponseHeader.statusCode == 'REF-TOK-SUC-200') {
        console.log("session renewal respose", res)
        if (value == 'changePassword') {
          this.changePassword();
        }
        if (value == 'validatesession') {
          this.validateSession()
        }
      }
      else {
        console.log("Session timeout / LOGOUT");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    }, (error) => {
      console.log("Error occured: " + error);
      this.ErrorsPopupModelDispay = true;
      this.promptmessage = "Session has expired, Please re-login";
    })
  }

  Signout() {
    this.os.logout().subscribe((response: any) => {
      if (response) {
        localStorage.clear();
        window.location.href = this.os.getDslMonitoringUrl();
      }
    },
      (error) => {
        console.log("Error occured: " + error);
        window.location.href = this.os.getDslMonitoringUrl();
      }
    )
  }

  closepopup() {
    this.ErrorsPopupModelDispay = false;
    localStorage.clear();
    window.location.href = this.os.getDslMonitoringUrl();
  }

  homePage() {
    window.location.href = this.os.getDslMonitoringUrl() + '/homepage';
  }
}

