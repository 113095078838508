        <div class="leftpane">
            <app-left-panel></app-left-panel>
          </div>
          <div class="rightpane" [ngClass]="{'showHeadclass': !showHeader}">
            <app-header *ngIf="showHeader"></app-header>
            <router-outlet (activate)="modifyHeader()"></router-outlet>
          </div>
          
          <div *ngIf="ErrorsPopupModelDispay" class="custom-model">
            <div class="model">
                <div class="model-body">
                    <div class="modal-header">
                        <h5 class="modal-title">Session Expired</h5>
                    </div>
                    <p class="promptMsg">{{promptmessage}}</p>
                    <div class="buttonclass"><button class="buttonprimary" (click)="closepopup()">OK</button>&nbsp;&nbsp;&nbsp;</div>
                </div>
            </div>
            <div class="model-background"></div>
        </div>