import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Operators } from '../Operators';
import { OperatorService } from '../services/operator.service';
import { ModalDismissReasons, NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { analyzeAndValidateNgModules, debugOutputAstAsTypeScript } from '@angular/compiler';
import { Pipe } from '@angular/core';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}


/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';
  months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    //console.log(date);
    //console.log(moment(date));
    return date ? date.day + this.DELIMITER + this.months[date.month - 1] + this.DELIMITER + date.year : '';
  }
}



@Component({
  selector: 'app-add-operator',
  templateUrl: './add-operator.component.html',
  styleUrls: ['./add-operator.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})

export class AddOperatorComponent implements OnInit {
  operatorForm: any;

  dateValidation: boolean = false;
  PopupModelDispay: boolean = false;
  ErrorsPopupModelDispay: boolean = false;
  promptmessage: string = "";
  spiner: boolean = false;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  public model: any = "18-04-2021";
  finalDate: any;

  model1!: string;
  model2!: string;
  totalDeviceCount: any;
  checkOprIdFormat: boolean = false;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private _router: Router, private os: OperatorService, private modalService: NgbModal, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {

    this.os.getCurrentUser();
    this.finalDate = "18-04-2021"

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    //console.log(this.fromDate);
    //console.log(this.toDate);
  }

  operators: Operators[] = [];

  onDateSelect(event: any) {
    //console.log(event);
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;

    this.finalDate = day + "-" + month + "-" + year;

    // this.operatorForm.controls['NotificationDate'].setValue(this.finalDate);

    // this.operatorForm.patchValue({
    //   OperatorID: "1111"});


    this.model = this.finalDate;
  }

  //validation messages
  ValidationMessages = {
    'OperatorID': {
      'required': 'OperatorID required.'
    },
    'UniqueEntityNumber': {
      'required': 'Unique Entity Number required.'
    },
  };

  //FormErrors
  formErrors = {
    'OperatorID': '',
    'UniqueEntityNumber': ''
  };


  logValidationErrors(group: FormGroup): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractContorl = group.get(key);
      if (abstractContorl instanceof FormGroup) { }
      else {
        abstractContorl?.markAsDirty();
      }
    });

  }


  AddOperator() {
    this.spiner = true;
    this.logValidationErrors(this.operatorForm);
    console.log(this.operatorForm.value)
    if (this.validateForms(this.operatorForm.value) && !this.checkOprIdFormat) {
      this.os.AddOperator(this.operatorForm.value).subscribe((response) => {
        if (response == "success") {
          this.spiner = false;
          this.PopupModelDispay = true;
          this.promptmessage = "Operator added successfully!";
          this.os.sendOprEmailNotification(this.operatorForm.value, 'add').subscribe((response) => {
            //console.log("response", response)
          }, (error) => {
            console.log("Error occured: " + error);
          })
        }
        else if (response.code == "PROTOCOL_ENQUEUE_AFTER_QUIT") {
          this.spiner = false;
          this.PopupModelDispay = true;
          this.promptmessage = response.code;
        }
        else if (response.code == "ER_DUP_ENTRY") {
          this.spiner = false;
          this.ErrorsPopupModelDispay = true;
          this.promptmessage = "Operator already exists!";
        }
      },
        (error) => {
          this.spiner = false;
          //this.PopupModelDispay = true;
          console.log("Error occured: " + error);
        })
    }
    else {
      this.spiner = false;
      this.ErrorsPopupModelDispay = true;
      this.promptmessage = "Please enter the correct value!";
      console.log("not validated")
    }
  }


  validateForms(opr: any) {
    var diffInDays: any;
    var startdate: any;
    var enddate: any;
    // var newDate = new Date(opr.NotificationDate);
    // var newDate1 = new Date(opr.OperatorEndDate);

    var NotificationDatefirst = opr.NotificationDate.indexOf("-");
    var NotificationDatelast = opr.NotificationDate.lastIndexOf("-");
    var NotificationDatelen = opr.NotificationDate.length;
    var NotificationDated = opr.NotificationDate.substring(0, NotificationDatefirst)
    var NotificationDatem = opr.NotificationDate.substring(NotificationDatefirst + 1, NotificationDatelast);
    var NotificationDatey = opr.NotificationDate.substring(NotificationDatelast + 1, NotificationDatelen);


    var OperatorEndDatefirst = opr.OperatorEndDate.indexOf("-");
    var OperatorEndDatelast = opr.OperatorEndDate.lastIndexOf("-");
    var OperatorEndDatelen = opr.OperatorEndDate.length;
    var OperatorEndDated = opr.OperatorEndDate.substring(0, OperatorEndDatefirst)
    var OperatorEndDatem = opr.OperatorEndDate.substring(OperatorEndDatefirst + 1, OperatorEndDatelast);
    var OperatorEndDatey = opr.OperatorEndDate.substring(OperatorEndDatelast + 1, OperatorEndDatelen);

    enddate = moment([OperatorEndDatey, OperatorEndDatem, OperatorEndDated]);
    startdate = moment([NotificationDatey, NotificationDatem, NotificationDated]);

    diffInDays = enddate.diff(startdate, 'days');

    if (opr.OperatorEndDate != "") {
      if (diffInDays < 0) {
        this.dateValidation = true;
      }
      else {
        this.dateValidation = false;
      }
    }
    else {
      this.dateValidation = false;
    }

    if (opr.OperatorID == "" || opr.UniqueEntityNumber == "" || opr.OperatorShortName == "" || opr.OprPhoneNumber == "" || opr.OprEmailAddress == "" ||
      opr.ComplianceDeclaration == "" || opr.NotificationDate == "" || opr.Rep1ContactPersonName == "" || opr.Rep1SingaporeAddress == "" || opr.Rep1PhoneNumber == "" || opr.Rep1EmailAddress == "" || opr.DeviceTypeClass1 == "" || opr.DeviceTypeClass2 == "" || opr.DeviceTypeClass3 == "" || (opr.OperatorEndDate != "" && (diffInDays < 0))) {
      return 0;
    }
    else {
      return 1;
    }
  }

  closepopup() {
    this.ErrorsPopupModelDispay = false;
  }


  CharsNumbersOnly(e: any): boolean {
    var regex = new RegExp("^[a-zA-Z0-9- ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    else {
      return false;
    }
  }

  operatorID(e: any): boolean {
    //var regex = new RegExp("^[CL0-9-]+$");
    var regex = new RegExp("^[a-zA-Z0-9-]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    else {
      return false;
    }
  }


  checkidFormat() {
    const regExAll = /^CL-[0-9]+$/;
    if (regExAll.test(this.operatorForm.value.OperatorID)) {
      this.checkOprIdFormat = false;
    }
    else {
      this.checkOprIdFormat = true;
    }
  }


  NumbersOnly(event: any): boolean {
    const charcode = (event.whiich) ? event.which : event.keyCode;

    if (charcode > 31 && (charcode < 48 || charcode > 57)) {
      return false;
    }
    return true;
  }

  NumbersOnlyphone(event: any, val: string): boolean {

    const charcode = (event.whiich) ? event.which : event.keyCode;
    //console.log(charcode);
    //console.log(val);

    if (val == "") {
      if (charcode == 43)
        return true;
      else
        return false;
    }
    else {
      if (charcode > 31 && (charcode < 48 || charcode > 57)) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  totalDevicesCount(event: any) {
    this.totalDeviceCount = Number(this.operatorForm.controls.DeviceTypeClass1.value) + Number(this.operatorForm.controls.DeviceTypeClass2.value) + Number(this.operatorForm.controls.DeviceTypeClass3.value)
  }

  clearDate() {
    this.operatorForm.patchValue({
      OperatorEndDate: ""
    });
  }


  // onDateSelection(date: NgbDate) {
  //   console.log("onDateSelection");
  //   if (!this.fromDate && !this.toDate) {
  //     this.fromDate = date;
  //   } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
  //     this.toDate = date;
  //   } else {
  //     this.toDate = null;
  //     this.fromDate = date;
  //   }
  // }

  ngOnInit(): void {
    this.operatorForm = this.formBuilder.group({
      OperatorID: ['', [Validators.required]],
      UniqueEntityNumber: ['', [Validators.required]],
      OperatorShortName: ['', [Validators.required]],
      OperatorLongName: [''],

      OprContactPersonName: [''],
      OprRegisteredBusinessAddress: [''],
      OprSingaporeAddress: [''],

      //OprPhoneNumberPlus: ['+', [Validators.required]],
      OprPhoneNumberCode: ['', [Validators.required]],
      OprPhoneNumber: ['', [Validators.required]],
      OprEmailAddress: ['', [Validators.required]],


      Rep1ContactPersonName: ['', [Validators.required]],
      Rep1SingaporeAddress: ['', [Validators.required]],
      //Rep1PhoneNumber:[''],
      Rep1PhoneNumberCode: ['', [Validators.required]],
      Rep1PhoneNumber: ['', [Validators.required]],
      Rep1EmailAddress: ['', [Validators.required]],

      Rep2ContactPersonName: [''],
      Rep2SingaporeAddress: [''],
      //Rep2PhoneNumber: [''],
      Rep2PhoneNumberCode: [''],
      Rep2PhoneNumber: [''],
      Rep2EmailAddress: [''],


      // DeviceTypeID: ['', [Validators.required]],
      DeviceTypeClass1: ['', [Validators.required]],
      DeviceTypeClass2: ['', [Validators.required]],
      DeviceTypeClass3: ['', [Validators.required]],
      TotalDevices: [''],
      NotificationDate: ['', [Validators.required]],
      OperatorEndDate: ['', [Validators.required]],
      ComplianceDeclaration: ['Yes', [Validators.required]]
    });

  }
}
