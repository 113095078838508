export const unauthLogin = {
    title : "Unauthorised Access",
    desc : "Please log in with valid credentials to access this page."
}

export const pageNotFound = {
    title : "404 - Page Not Found",
    desc : "We couldn't find the page you're looking for."
}

export const loginErrorCode = {
    unAuthUAT : "UNAUTHORIZED-ERR-101",
    unAuthProd : "VAL-TOK-ERR-102"
}