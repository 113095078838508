import { Component, OnInit } from '@angular/core';
import { OperatorService } from '../services/operator.service';

@Component({
  selector: 'app-maincomponent',
  templateUrl: './maincomponent.component.html',
  styleUrls: ['./maincomponent.component.scss']
})
export class MaincomponentComponent implements OnInit {

  constructor(private os: OperatorService) {
    this.os.getCurrentUser();
   }

  ngOnInit(): void {
  }

}
