import { Component, OnInit } from '@angular/core';
import { Operators } from '../Operators';
import { OperatorService } from '../services/operator.service';
//import * as data from '../../../db.json';

@Component({
  selector: 'app-operator-management',
  templateUrl: './operator-management.component.html',
  styleUrls: ['./operator-management.component.scss']
})
export class OperatorManagementComponent implements OnInit {

  constructor(private os : OperatorService) { 
    this.os.getCurrentUser();
  }
  // columns = ["Operator ID","Unique Entity Number","Operator ShortName","Rep1 Contact PersonName", "Rep2 Contact PersonName"]

  // index= ["OperatorID","UniqueEntityNumber","OperatorShortName","Rep1ContactPersonName", "Rep2ContactPersonName"]

  operators:Operators[] = [];

  ngOnInit(): void {
    this.os.getOperators().subscribe
    (
      (response)=>
      {
        this.operators = response;
      },
      (error)=>
      {
        console.log("Error occured: " + error);
      }
    )
  }

}

