import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { fileRecords } from './filehistory'
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FileHistoryService {
  // const httpOptions = {
  //   headers: new HttpHeaders({ 
  //     'Access-Control-Allow-Origin':'*',
  //     'Authorization':'authkey',
  //     'userid':'1'
  //   })
  // };

  //public baseUrl = 'https://lbco1m2m3k.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-file-history';
    public baseUrl = 'https://cubs-uat-api.dslmonitoring.net/get-file-hist/filehistory';
  // public baseUrl = 'https://cubs-api.dslmonitoring.net/get-file-hist/filehistory';

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getAllFiles(): Observable<any> {
    let API_URL = `${this.baseUrl}`;
    //return this.http.get<fileRecords[]>(API_URL)
    return this.http.get<fileRecords[]>(API_URL, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true })
      // .pipe(
      //   catchError(this.error)
      // )
  }
  // error(error: HttpErrorResponse) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     errorMessage = error.error.message;
  //   } else {
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   console.log(errorMessage);
  //   return throwError(errorMessage);
  // }

}
