import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './Error/error.component';
import { MaincomponentComponent } from './maincomponent/maincomponent.component';
import { DataUploadComponent } from './data-upload/data-upload.component';
import { FileHistoryComponent } from './file-history/file-history.component';
import { OperatorManagementComponent } from './operator-management/operator-management.component'
import { ViewOperatorComponent } from './view-operator/view-operator.component'
import { AddOperatorComponent } from './add-operator/add-operator.component'
import { FileApprovalComponent } from './file-approval/file-approval.component'
import { EditOperatorComponent } from './edit-operator/edit-operator.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuicksightComponent } from './quicksight/quicksight.component';
import { UserBanSearchComponent } from './user-ban-search/user-ban-search.component';
import { pageNotFound } from './Error/constants';
const routes: Routes = [
  
 // { path: '', component: MaincomponentComponent},
  { path: 'errorComponent', component: ErrorComponent },
  { path: 'mainComponent', component: MaincomponentComponent},
  // { path: '', component: MaincomponentComponent },
  { path: 'dataUpload', component: DataUploadComponent },
  { path: 'fileHistory', component: FileHistoryComponent },
  { path: 'operatormanagement', component: OperatorManagementComponent },
  { path: 'viewOperator', component: ViewOperatorComponent },
  { path: 'addOperator', component: AddOperatorComponent },
  { path: 'editOperator/:OperatorID', component: EditOperatorComponent },
  { path: 'fileapproval', component: FileApprovalComponent },
  { path: 'quicksight', component: QuicksightComponent },
  { path: 'userBanSearch', component: UserBanSearchComponent},
  { path: '**', redirectTo: `/errorComponent?errorTitle=${pageNotFound?.title}&errorDecription=${pageNotFound?.desc}&hideBtn=${true}` }

];

@NgModule({
  imports: [RouterModule.forRoot(routes), FormsModule,
    ReactiveFormsModule],
  exports: [RouterModule, FormsModule,
    ReactiveFormsModule]
})
export class AppRoutingModule { }
