import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import * as Moment from 'moment';
import 'moment-timezone';
import { OperatorService } from '../services/operator.service';

// import * as Papa from 'papaparse';

@Component({
  selector: 'app-user-ban-search',
  templateUrl: './user-ban-search.component.html',
  styleUrls: ['./user-ban-search.component.scss'],
  providers: [],
})

export class UserBanSearchComponent implements OnInit {
  multipleSearchForm: FormGroup;
  public searchText: any;
  p: number = 1;
  key: string = '';
  reverse: boolean = false;
  isLoading: boolean = false;
  ErrorsPopupModelDispay: boolean = false;
  sessionErrorsPopupModelDispay: boolean = false;
  promptmessage: string = "";
  promptTitle: string = "";
  isToggled: boolean = false;
  isadded: boolean = false;
  // fileName: string = "Choose a .CSV file.";
  myArrayFiltered: any[] = [];
  updatedData: any[] = [];
  public selectedObj: {} = {};
  countryCodePattern = '^\\+[0-9]+$';
  // concatPhNumber:any[] = [];
  newPhoneNumber: any[] = [];
  underService: boolean = false;

  constructor(
    private fb: FormBuilder,
    private os: OperatorService,
    private renderer: Renderer2
  ) {
    this.os.getCurrentUser();
    this.multipleSearchForm = this.fb.group({
      countryCode: ['+65', [Validators.minLength(2), Validators.maxLength(4), Validators.required, Validators.pattern(this.countryCodePattern)]],
      mobnum: ['', [Validators.minLength(1), Validators.required]],
      banFormArray: this.fb.array([])
    });
  }

  get countryCode() {
    return this.multipleSearchForm.get('countryCode') as FormControl;
  }

  get mobnum() {
    return this.multipleSearchForm.get('mobnum') as FormControl;
  }

  myData: any[] = [
    { UNIQUE_ID: "", BANSTART: "", BANEND: "", OPR_TYPE: "", BANSTATUSIND: '' }
  ];

  ngOnInit(): void {
    const banFormArray = (this.multipleSearchForm.get('banFormArray') as FormArray);
    banFormArray.push(this.fb.group({
      countryCode: ['+65', [Validators.minLength(2), Validators.maxLength(4), Validators.required, Validators.pattern(this.countryCodePattern)]],
      mobnum: ['', [Validators.minLength(1), Validators.required]]
    }));
  }

  get banFormArray(): FormArray {
    return this.multipleSearchForm.get('banFormArray') as FormArray;
  }

  addInput(i: any): void {
    this.isadded = true;
    let inputField = (<FormArray>this.multipleSearchForm.get('banFormArray')).controls[i];
    if (this.banFormArray.valid) {
      this.banFormArray.push(this.fb.group({
        countryCode: ['+65', [Validators.minLength(2), Validators.maxLength(4), Validators.required, Validators.pattern(this.countryCodePattern)]],
        mobnum: ['', [Validators.minLength(1), Validators.required]]
      }));
      this.isadded = !this.isadded;
    }
    //if (!this.banFormArray.valid) {
    // if (this.banFormArray.controls.length == 10) {
    //   this.ErrorsPopupModelDispay = true;
    //   this.promptTitle = "Maximum Limit";
    //   this.promptmessage = 'You have reached maximum limit';
    // } else
    if (inputField.value['mobnum'] == '') {
      this.ErrorsPopupModelDispay = true;
      this.promptTitle = "To add more number"
      this.promptmessage = 'Please input mobile number in empty field.';
    } else {
      this.ErrorsPopupModelDispay = false;
    }
    //}
  }

  deleteInput(index: any): void {
    this.banFormArray.removeAt(index);
  }

  // use this in HTML : ===== > (keydown)="onPress($event,search.value)"

  // onPress(event: any, value: string): void {
  //   switch (event.keyCode) {
  //     // keycode 13 = enter
  //     case 13: {
  //       if (value && value.trim() !== '') {
  //         if (this.items.controls.length === 1 || this.items.controls.length < 10) {
  //           this.addInput();
  //         }
  //       }
  //     }
  //       break;
  //   }
  // }

  onSubmit(): void {
    this.isLoading = true;
    // console.log(this.banFormArray.getRawValue());
    if (this.banFormArray.valid) {
      this.callApi();
    } else {
      this.isLoading = false;
    }

    if (this.banFormArray.length === 1 && this.banFormArray.invalid) {
      this.ErrorsPopupModelDispay = true;
      this.promptTitle = "Empty Field";
      this.promptmessage = "Please input mobile number in empty field.";
    } else if (this.banFormArray.length > 1 && this.banFormArray.invalid) {
      this.ErrorsPopupModelDispay = true;
      this.promptTitle = "Remove Empty Field";
      this.promptmessage = "Click on the 'X' icon to remove empty field before submitting.";
    } else {
      this.ErrorsPopupModelDispay = false;
    }

  }

  sessionRenewal(newPhoneNumber: any) {
    this.os.sessionTokenRenewal().subscribe((res: any) => {
      console.log("RESPONSE ERROR", res)
      if (res && res.serviceResponseHeader.statusCode == 'REF-TOK-SUC-200') {
        console.log("session renewal respose", res)
        this.os.banSearch(newPhoneNumber).subscribe((data) => {
          console.log("111", data)
          if (data && data.message) {
            this.underService = true;
            this.isLoading = false
          }
          else {
            data.forEach((obj: any) => {
              const isUnique = !this.myData.some(i =>
                i.UNIQUE_ID === obj.UNIQUE_ID &&
                i.BANSTART === obj.BANSTART &&
                i.BANEND === obj.BANEND &&
                i.OPR_TYPE === obj.OPR_TYPE &&
                i.BANSTATUSIND === obj.BANSTATUSIND
              );
              if (isUnique) {
                this.myData.push(obj);
              }
            })
            // console.log("this.myData", this.myData);
            this.addInvalidNumber();
            this.isLoading = false;
          }
        }, error => {
          console.log(error);
          this.isLoading = false;
          console.log("Session timeout / LOGOUTT");
          this.sessionErrorsPopupModelDispay = true;
          this.promptmessage = "Session has expired, Please re-login";
        });
      }
      else {
        console.log("Session timeout / LOGOUT 1");
        this.sessionErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    }, (error) => {
      console.log("Error occured: " + error);
      console.log("SESSION TIMEOUT / LOGOUT 2")
      this.sessionErrorsPopupModelDispay = true;
      this.promptmessage = "Session has expired, Please re-login";
    })
  }

  callApi() {
    const PhoneNumber = this.banFormArray.controls.map(ele => ele.value);
    const concatPhNumber = PhoneNumber.map(i => i.countryCode + i.mobnum);
    this.newPhoneNumber = [...new Set([...concatPhNumber, ...concatPhNumber.map(i => i.replace(/^\+/, ''))])];
    this.os.banSearch(this.newPhoneNumber).subscribe((data) => {
      // this.myData = data;'
      if (data && data.message) {
        this.underService = true;
        this.isLoading = false
      }
      else {
        this.underService = false;
        data.forEach((obj: any) => {
          const isUnique = !this.myData.some(i =>
            i.UNIQUE_ID === obj.UNIQUE_ID &&
            i.BANSTART === obj.BANSTART &&
            i.BANEND === obj.BANEND &&
            i.OPR_TYPE === obj.OPR_TYPE &&
            i.BANSTATUSIND === obj.BANSTATUSIND
          );
          if (isUnique) {
            this.myData.push(obj);
          }
        })
        // console.log("this.myData", this.myData);
        this.addInvalidNumber();
        this.isLoading = false;
      }

    }, error => {
      console.log(error);
      if (error.error.serviceResponseHeader && (error.error.serviceResponseHeader.statusCode == 'UNAUTHORIZED-ERR' || error.error.serviceResponseHeader.statusCode == 'VAL-TOK-ERR-105')) {
        this.sessionRenewal(this.newPhoneNumber);
      }
      else {
        this.isLoading = false;
        console.log("Session timeout / LOGOUTT");
        this.sessionErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    });
  }

  addInvalidNumber() {
    this.updatedData = this.myData.map(ele => {
      const startDate = this.updateDate(ele.BANSTART, ele.BANSTATUSIND);
      const endDate = this.updateDate(ele.BANEND, ele.BANSTATUSIND);
      const status = this.formateStatus(ele.BANSTATUSIND);
      return {
        ...ele,
        BANSTART: startDate,
        BANEND: endDate,
        BANSTATUSIND: status
      }
    });
    this.addObj();
  }

  updateDate(dateString: any, banStatus: any) {
    if (banStatus !== 1) {
      return 'N.A';
    } else {
      return dateString;
    }
  }

  formateStatus(status: any) {
    let statusData;
    if (status == '1') {
      statusData = 'BANNED';
    }
    else {
      statusData = 'N.A';
    }
    return statusData
  }

  addObj() {
    const newData = this.banFormArray.controls.filter(ele => !this.updatedData.some(i =>
      (i.UNIQUE_ID === ele.value.countryCode + ele.value.mobnum) || (i.UNIQUE_ID === ele.value.countryCode.substring(1) + ele.value.mobnum)))
      .map(element => {
        // console.log('UNIQUE_ID', element.value.countryCode+ element.value.mobnum);
        // const concatPhNumber = element.map(i => i.countryCode + i.mobnum);
        // const newObj: { [key: string]: any } = { "UNIQUE_ID": element.value.countryCode+ ' ' + element.value.mobnum, "BANSTATUSIND": 'NOT BANNED' };
        const newObj: { [key: string]: any } = { "UNIQUE_ID": element.value.countryCode + element.value.mobnum };
        for (const key in this.updatedData[0]) {
          if (key !== "UNIQUE_ID") {
            newObj[key] = 'N.A';
          }
        }
        return newObj;
      });
    this.updatedData = this.updatedData.concat(newData);
    this.filterData();
  }

  filterData(): void {
    this.myArrayFiltered = this.updatedData.filter((el) => {
      return el.UNIQUE_ID !== '' && this.banFormArray.controls.some((f) => {
        return (f.value.countryCode + f.value.mobnum == el.UNIQUE_ID) || (f.value.countryCode.substring(1) + f.value.mobnum == el.UNIQUE_ID);
      });
    });
    // console.log('myArrayFiltered', this.myArrayFiltered);
    this.myArrayFiltered = this.myArrayFiltered.map(obj => {
      const matchingEntry = this.banFormArray.controls.find(entry =>
        obj['UNIQUE_ID'] === entry.value.countryCode + entry.value.mobnum ||
        obj['UNIQUE_ID'] === entry.value.countryCode.substring(1) + entry.value.mobnum
      );
      if (matchingEntry) {
        obj['UNIQUE_ID'] = matchingEntry.value.countryCode + ' ' + matchingEntry.value.mobnum;
      }
      return obj;
    });

    // pure uniqueSet
    let uniqueSet = new Set(this.myArrayFiltered.map(obj => JSON.stringify(obj)));
    this.myArrayFiltered = Array.from(uniqueSet).map(str => JSON.parse(str));
    // this.auditDataAPI();
  }

  // auditDataAPI() {
  //   // let currentUser = localStorage.getItem();
  //   // let currentUserEmailid = localStorage.getItem();
  //   let currentUser = 'anusha';
  //   let currentUserEmailid = 'anusha@wipro.com';
  //   let records = [...new Set(this.myData.map((ele) => ele.USER_HP))].slice(1).toString();
  //   let phoneNumber = this.items.controls.map(ele => ele.value).toString();
  //   this.selectedObj = {
  //     "user": currentUser,
  //     "userEmailId": currentUserEmailid,
  //     "phoneNumberSearched": phoneNumber,
  //     "phoneNoSearchResult": records,
  //     "CREATED_DTTM": Moment().tz("Asia/Singapore").format("DD-MMM-YYYY HH:mm:ss"),
  //   }
  //   //console.log("SEARCHED_BY", currentUser +''+ currentUserEmailid)
  //   this.ss.auditBan(this.selectedObj).subscribe(
  //     (response) => {
  //       console.log('response', response);
  //     },
  //     (error) => {
  //       console.log("Error occured: " + error);
  //     }
  //   )


  // }


  // ========== Upload CSV Function ===========

  // csvImport(event: any) {
  //   let file: File = event.target.files[0]; //to store data
  //   this.fileName = file ? file.name : "Choose a .CSV file.";

  //   let reader: FileReader = new FileReader(); // to read data as text
  //   reader.readAsText(file);

  //   //to read data from .CSV file
  //   reader.onload = async (e) => {
  //     var csv: string = reader.result as string;
  //     var parsedData = Papa.parse(csv, { header: true }).data;
  //     console.log('parsedData',parsedData);
  //     this.items = parsedData.map(data => (<{ PhoneNumber: string }>data).PhoneNumber);
  //     console.log('this.items', this.items);
  //     this.triggerChange(); //call trigger method

  //   }
  // }

  sort(key: any) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  // sortByID() {
  //   if (!this.isToggled) {
  //     this.myArrayFiltered.sort((a, b) => {
  //       // country code
  //       return parseInt(a.UNIQUE_ID.split(' ')[0]) - parseInt(b.UNIQUE_ID.split(' ')[0]);
  //     });
  //   } else {
  //     this.myArrayFiltered.sort((a, b) => {
  //       // mobile number
  //       return parseInt(a.UNIQUE_ID.split(' ')[1]) - parseInt(b.UNIQUE_ID.split(' ')[1]);
  //     });
  //   }
  //   this.isToggled = !this.isToggled;
  // }

  sortByCD() {
    if (!this.isToggled) {
      this.myArrayFiltered.sort((a: any, b: any) => {
        return parseInt(a.UNIQUE_ID.split(' ')[0]) - parseInt(b.UNIQUE_ID.split(' ')[0])
      });
    } else {
      this.myArrayFiltered.sort((a: any, b: any) => {
        return parseInt(b.UNIQUE_ID.split(' ')[0]) - parseInt(a.UNIQUE_ID.split(' ')[0])
      });
    }
    this.isToggled = !this.isToggled;
  }

  sortByPH() {
    if (!this.isToggled) {
      this.myArrayFiltered.sort((a: any, b: any) => {
        return parseInt(a.UNIQUE_ID.split(' ')[1]) - parseInt(b.UNIQUE_ID.split(' ')[1])
      });
    } else {
      this.myArrayFiltered.sort((a: any, b: any) => {
        return parseInt(b.UNIQUE_ID.split(' ')[1]) - parseInt(a.UNIQUE_ID.split(' ')[1])
      });
    }
    this.isToggled = !this.isToggled;
  }

  sortByDate(key: any) {
    if (!this.isToggled) {
      this.myArrayFiltered.sort(function (a: any, b: any) {
        //'N.A should appear at bottom
        if (a[key] == 'N.A' && b[key] !== 'N.A') return 1;
        if (a[key] !== 'N.A' && b[key] == 'N.A') return -1;

        return (a[key] == 'N.A' ? <any>new Date('01-03-1980') : <any>new Date(a[key])) - (b[key] == 'N.A' ? <any>new Date('01-03-1980') : <any>new Date(b[key]));
      });
    } else {
      this.myArrayFiltered.sort(function (a: any, b: any) {
        //'N.A should appear at top
        if (a[key] == 'N.A' && b[key] !== 'N.A') return -1;
        if (a[key] !== 'N.A' && b[key] == 'N.A') return 1;

        return (b[key] == 'N.A' ? <any>new Date('01-03-1980') : <any>new Date(b[key])) - (a[key] == 'N.A' ? <any>new Date('01-03-1980') : <any>new Date(a[key]));
      });
    }
    // console.log(this.myArrayFiltered);
    this.isToggled = !this.isToggled;
  }

  NumbersOnly(event: any): boolean {
    const charcode = (event.whiich) ? event.which : event.keyCode;
    if (charcode > 31 && (charcode < 48 || charcode > 57)) {
      return false;
    }
    return true;
  }

  NumbersOnlyphone(event: any, val: string): boolean {
    const charcode = (event.whiich) ? event.which : event.keyCode;
    //console.log(charcode);
    //console.log(val);
    if (val == "") {
      if (charcode == 43)
        return true;
      else
        return false;
    }
    else {
      if (charcode > 31 && (charcode < 48 || charcode > 57)) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  onPaste(event: Event, index: number): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    // console.log('inputValue', inputValue);
    if (/ [a - zA - Z] /.test(inputValue)) {
      inputElement.value = '';
      const phoneGroup = this.banFormArray.controls[index] as FormGroup;
      const phoneControl = phoneGroup.get('mobnum');

      if (phoneControl) {
        phoneControl.setValue('');
      }
      return;
    }

    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
    inputElement.value = sanitizedValue;
    const phoneGroup = this.banFormArray.controls[index] as FormGroup;
    const phoneControl = phoneGroup.get('mobnum');
    if (phoneControl) {
      phoneControl.setValue(sanitizedValue);
    }
  }

  closepopup() {
    this.ErrorsPopupModelDispay = false;
  }

  closeErrPopup() {
    this.sessionErrorsPopupModelDispay = false;
    localStorage.clear();
    window.location.href = this.os.getDslMonitoringUrl();
  }


}

