import { Component, OnInit, HostListener, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Operators } from '../Operators';
import { OperatorService } from '../services/operator.service';


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}


/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';
  months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    //console.log(date);
    //console.log(moment(date));
    return date ? date.day + this.DELIMITER + this.months[date.month-1] + this.DELIMITER + date.year : '';
  }
}



@Component({
  selector: 'app-edit-operator',
  templateUrl: './edit-operator.component.html',
  styleUrls: ['./edit-operator.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class EditOperatorComponent implements OnInit {
  operatorForm: any;
  tempOprID:string ="";
  dateValidation :  boolean = false;

  disableTextbox:boolean =  false;
  PopupModelDispay :  boolean = false;
  ErrorsPopupModelDispay:  boolean = false;
  promptmessage:string = "";
  spiner: boolean = false;
  totalDeviceCount : any;
  editOperator: any;


  constructor(private _router: Router,private formBuilder: FormBuilder, private route:ActivatedRoute, private os: OperatorService) { 
    this.os.getCurrentUser();
  }

  getOperator(OperatorID:string)
  {
    this.spiner = true;
    this.os.getOperatorEdit(OperatorID).subscribe(
      (response)=>
      {
        this.operators = response;
        this.EditOperator(this.operators[0]);
        this.spiner = false;
      },
      (error)=>
      {
        console.log("Error occured: " + error);
        this.spiner = false;
      }
    )
  }

  EditOperator(Operators: Operators)
  {
    //segregate phone countrycode and number
    //console.log("Operators.OPR_START_DT");
    //console.log(Operators.OPR_START_DT);

    var StartDateY = Operators.OPR_START_DT.substring(0,4);
    var StartDateD = Operators.OPR_START_DT.substring(5,7)
    var StartDateM = Operators.OPR_START_DT.substring(8,10);

    var EndDateY = Operators.OPR_END_DT.substring(0,4);
    var EndDateD = Operators.OPR_END_DT.substring(5,7)
    var EndDateM = Operators.OPR_END_DT.substring(8,10);

    //console.log(StartDateY);
    //console.log(StartDateD);
    //console.log(StartDateM);

    var n = Operators.OPR_PHONE_NUM.indexOf(" ");
    var len = Operators.OPR_PHONE_NUM.length;
    var OprPhoneNumberCode = Operators.OPR_PHONE_NUM.substring(0,n);
    var OprPhoneNumber = Operators.OPR_PHONE_NUM.substring(n+1,len);

    var n = Operators.REP1_PHONE_NUM.indexOf(" ");
    var len = Operators.REP1_PHONE_NUM.length;
    var Rep1PhoneNumberCode = Operators.REP1_PHONE_NUM.substring(0,n);
    var Rep1PhoneNumber = Operators.REP1_PHONE_NUM.substring(n+1,len);


    var n = Operators.REP2_PHONE_NUM.indexOf(" ");
    var len = Operators.REP2_PHONE_NUM.length;
    var Rep2PhoneNumberCode = Operators.REP2_PHONE_NUM.substring(0,n);
    var Rep2PhoneNumber = Operators.REP2_PHONE_NUM.substring(n+1,len);

    this.operatorForm.patchValue({
      OperatorID: Operators.OPRID_NUM,
      UniqueEntityNumber : Operators.OPR_UEN,
      OperatorShortName: Operators.OPR_NAME,
      OperatorLongName: Operators.OPR_DESC,

      OprContactPersonName: Operators.OPR_CP_NAME,
      OprRegisteredBusinessAddress: Operators.OPR_REG_ADDR,
      OprSingaporeAddress: Operators.OPR_SG_ADDR,
      //OprPhoneNumber: Operators.OPR_PHONE_NUM,
      OprPhoneNumberCode: (OprPhoneNumberCode =='null') ? "" : OprPhoneNumberCode,
      OprPhoneNumber: (OprPhoneNumber == 'null') ? "" : OprPhoneNumber,
      OprEmailAddress: Operators.OPR_EMAIL_ID,

      Rep1ContactPersonName: Operators.REP1_CP_NAME,
      Rep1SingaporeAddress: Operators.REP1_SG_ADDR,
      //Rep1PhoneNumber: Operators.REP1_PHONE_NUM,
      Rep1PhoneNumberCode: (Rep1PhoneNumberCode == 'null') ? "" : Rep1PhoneNumberCode,
      Rep1PhoneNumber: (Rep1PhoneNumber == 'null') ? "" : Rep1PhoneNumber,
      Rep1EmailAddress: Operators.REP1_EMAIL_ID,

      Rep2ContactPersonName: Operators.REP2_CP_NAME,
      Rep2SingaporeAddress: Operators.REP2_SG_ADDR,
      //Rep2PhoneNumber: Operators.REP2_PHONE_NUM,
      Rep2PhoneNumberCode: (Rep2PhoneNumberCode == 'null') ? "" : Rep2PhoneNumberCode,
      Rep2PhoneNumber: (Rep2PhoneNumber == 'null') ? "" : Rep2PhoneNumber,
      Rep2EmailAddress: Operators.REP2_EMAIL_ID,

      DeviceTypeClass1: (Operators.REG_NO_OF_DEVICE_CLASS1 == '0') ? '0' : Operators.REG_NO_OF_DEVICE_CLASS1,
      DeviceTypeClass2: (Operators.REG_NO_OF_DEVICE_CLASS2 == '0') ? '0' : Operators.REG_NO_OF_DEVICE_CLASS2,
      DeviceTypeClass3:(Operators.REG_NO_OF_DEVICE_CLASS3 == '0') ? '0' : Operators.REG_NO_OF_DEVICE_CLASS3,

      // NotificationDate: [{year:2018,month:3,day:28}],
      // OperatorEndDate: [{year:2018,month:4,day:28}],

      //NotificationDate: [this.format(StartDateD + "-" + StartDateM +"-" + StartDateY)],
      //OperatorEndDate: [this.format(EndDateD + "-" + EndDateM +"-" + EndDateY)],

      NotificationDate: StartDateM+ "-" +StartDateD+ "-" + StartDateY,
      OperatorEndDate : EndDateM+ "-" +EndDateD+ "-" + EndDateY,
      ComplianceDeclaration: Operators.COMPLIANCE_DECLARATION

    });

    this.editOperator = this.operatorForm.value;
    console.log("this.editOperator", this.editOperator)

    setTimeout((): void => {
      this.totalDevicesCount(event);
    }, 1000);

  }

  format(date: any | null): string {

    let DELIMITER = '-';
    let months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    //console.log(date);
    //console.log(moment(date));
    //return date ? date.day + DELIMITER + months[date.month-1] + DELIMITER + date.year : '';
    var Y = date.substring(6,10);
    var D = date.substring(0,2);
    var M = date.substring(3,5);
    return date ? D + DELIMITER + months[M-1] + DELIMITER + Y : '';
  }

  CancelEdit()
  {
    this._router.navigate(['/viewOperator']);
  }


  ResetForm() 
  {
    this.tempOprID = this.operatorForm.value.OperatorID;
    this.operatorForm.reset();
    this.operatorForm.patchValue({
      OperatorID:this.tempOprID,
      ComplianceDeclaration: 'Yes'
    });
  }

  CharsNumbersOnly(e:any):boolean
  {
    var regex = new RegExp("^[a-zA-Z0-9-_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    else{
      return false;
    }
  }

  NumbersOnly(event:any):boolean
  {
    const charcode = (event.whiich) ? event.which: event.keyCode;

    if(charcode >31 && (charcode <48 || charcode >57))
    {
      return false;
    }
    return true;
  }

  NumbersOnlyphone(event:any, val:string):boolean 
  {

    const charcode = (event.whiich) ? event.which: event.keyCode;
    if (val  == "" )
    {
      if (charcode == 43)
        return true;
      else
        return false;
    }
    else
    {
      if(charcode >31 && (charcode <48 || charcode >57))
      {
        return false;
      }
      else
      {
        return true;
      }
    }
  }

  clearDate() {
    this.operatorForm.patchValue({
      OperatorEndDate: ""
    });
  }

  EditOperatortest() 
  {
    this.operatorForm.patchValue({
      OperatorID: "CL0001",
      UniqueEntityNumber : "UEN1234567",
      OperatorShortName: "Opr1",
      OperatorLongName: "Operator 1",

      OprContactPersonName: "John",
      OprRegisteredBusinessAddress: "Address1",
      OprSingaporeAddress: "Address2",
      OprPhoneNumber: "+65 12345678",
      OprEmailAddress: "john@gmail.com",

      Rep1ContactPersonName: "Rep1 Name",
      Rep1SingaporeAddress: "Rep1 Address",
      Rep1PhoneNumber: "+65 56784321",
      Rep1EmailAddress: "rep1@gmail.com",

      Rep2ContactPersonName: "Rep2 Name",
      Rep2SingaporeAddress: "Rep2 Address",
      Rep2PhoneNumber: "+65 87654321",
      Rep2EmailAddress: "rep2@gmail.com",

      //DeviceTypeID: "3",
      // NoofDevicesRegistered: "20",
      DeviceTypeClass1: "",
      DeviceTypeClass2: "",
      DeviceTypeClass3: "",
      NotificationDate:  this.formatDate("08/03/2021"),
      OperatorEndDate: this.formatDate("9/9/9999"),
      ComplianceDeclaration: "Yes"
    });
  }


  private formatDate(date:any) {
    return "27-AUG-2021";
  }


  ngOnInit(): void {
    this.operatorForm = this.formBuilder.group({
      OperatorID: ['', [Validators.required]],
      UniqueEntityNumber: ['', [Validators.required]],
      OperatorShortName: ['', [Validators.required]],
      OperatorLongName: ['', [Validators.required]],

      OprContactPersonName: ['', [Validators.required]],
      OprRegisteredBusinessAddress: ['', [Validators.required]],
      OprSingaporeAddress: ['', [Validators.required]],
      OprPhoneNumberCode: ['', [Validators.required]],
      OprPhoneNumber: ['', [Validators.required]],
      OprEmailAddress: ['', [Validators.required]],

      Rep1ContactPersonName: ['', [Validators.required]],
      Rep1SingaporeAddress: ['', [Validators.required]],
      Rep1PhoneNumberCode: ['', [Validators.required]],
      Rep1PhoneNumber: ['', [Validators.required]],
      Rep1EmailAddress: ['', [Validators.required]],

      Rep2ContactPersonName: ['', [Validators.required]],
      Rep2SingaporeAddress: ['', [Validators.required]],
      Rep2PhoneNumberCode: ['', [Validators.required]],
      Rep2PhoneNumber: ['', [Validators.required]],
      Rep2EmailAddress: ['', [Validators.required]],


      //DeviceTypeID: ['', [Validators.required]],
      DeviceTypeClass1: ['', [Validators.required]],
      DeviceTypeClass2: ['', [Validators.required]],
      DeviceTypeClass3: ['', [Validators.required]],
      TotalDevices: [''],
      NotificationDate: ['', [Validators.required]],
      OperatorEndDate: ['', [Validators.required]],
      ComplianceDeclaration: ['', [Validators.required]]
    });

    this.route.paramMap.subscribe(params =>{
      const OperatorID = params.get('OperatorID')
      if (OperatorID)
      {
        this.getOperator(OperatorID);
      }
    });

  }

  logValidationErrors(group: FormGroup):void{
    Object.keys(group.controls).forEach((key:string)=>{
      const abstractContorl = group.get(key);
      if (abstractContorl instanceof FormGroup)
      {

      }
      else 
      {
        abstractContorl?.markAsDirty();
      }

    });

  }
  operators:Operators[] = [];
  UpdateOperator(event: any)
  {
    this.logValidationErrors(this.operatorForm);
    if(this.validateForms(this.operatorForm.value)){
      {
        console.log("updating operator")
        this.os.UpdateOperator(this.operatorForm.value).subscribe
          (
            (response)=>
            {
              this.PopupModelDispay = true;
              this.promptmessage = "Operator updated successfully!";
              this.sendEmailNotification();
            },
            (error)=>
            {
              console.log("Error occured: " + error);
            }
          )
      }
    }
    else{
      this.ErrorsPopupModelDispay = true;
      this.promptmessage = "Please enter the correct value!";
      console.log("not validated")
    }
  }

  sendEmailNotification() {
    let previousValues = this.editOperator;
    let newValues = this.operatorForm.value;
    let diff = Object.keys(newValues).reduce((diff, key) => {
      if (previousValues[key] === newValues[key]) return diff
      //console.log("...diff", { ...diff, [key]: newValues[key] })
      return {
        ...diff,
        [key]: newValues[key]
      }
    }, {})

    let changedValues:any = diff;
    // this.loope = Object.keys(changedValues).forEach(element => {
    //   this.values = `${element}: ${changedValues[element]}`;
    //   this.values = [];
    //   this.test.push(this.values);
    // });
    let emailObj = {
      'previousValues': previousValues,
      'newValues': newValues,
      'ChangedValues': changedValues
    }

    this.os.sendOprEmailNotification(emailObj, 'edit').subscribe((response) => {
     // console.log("response", response)
    }, (error) => {
      console.log("Error occured: " + error);
    })
  }

  closepopup() 
  {
    this.ErrorsPopupModelDispay = false;
  }

  validateForms(opr: any)
  {
    var diffInDays:any;
    var startdate:any;
    var enddate:any;

    var NotificationDatefirst = opr.NotificationDate.indexOf("-");
    var NotificationDatelast = opr.NotificationDate.lastIndexOf("-");
    var NotificationDatelen = opr.NotificationDate.length;
    var NotificationDated = opr.NotificationDate.substring(0,NotificationDatefirst)
    var NotificationDatem = opr.NotificationDate.substring(NotificationDatefirst+1,NotificationDatelast);
    var NotificationDatey = opr.NotificationDate.substring(NotificationDatelast+1,NotificationDatelen);


    var OperatorEndDatefirst = opr.OperatorEndDate.indexOf("-");
    var OperatorEndDatelast = opr.OperatorEndDate.lastIndexOf("-");
    var OperatorEndDatelen = opr.OperatorEndDate.length;
    var OperatorEndDated = opr.OperatorEndDate.substring(0,OperatorEndDatefirst)
    var OperatorEndDatem = opr.OperatorEndDate.substring(OperatorEndDatefirst+1,OperatorEndDatelast);
    var OperatorEndDatey = opr.OperatorEndDate.substring(OperatorEndDatelast+1,OperatorEndDatelen);

    enddate = moment([OperatorEndDatey, OperatorEndDatem, OperatorEndDated]);
    startdate = moment([NotificationDatey, NotificationDatem, NotificationDated]);

    diffInDays = enddate.diff(startdate, 'days');

    if (opr.OperatorEndDate != "") 
    {
      // if (opr.NotificationDate > opr.OperatorEndDate)
      if (diffInDays < 0) 
      {
        this.dateValidation=true;
      }
      else 
      {
        this.dateValidation=false;
      }
    }
    else 
    {
      this.dateValidation=false;
    }


    if (opr.OperatorID == "" || opr.UniqueEntityNumber== "" || opr.OperatorShortName == "" || opr.OprPhoneNumber =="" || opr.OprEmailAddress =="" ||
      opr.ComplianceDeclaration =="" || opr.NotificationDate == "" || opr.Rep1ContactPersonName == "" || opr.Rep1SingaporeAddress == "" || opr.Rep1PhoneNumber == "" || opr.Rep1EmailAddress == "" || opr.DeviceTypeClass1 == "" || opr.DeviceTypeClass2 == "" || opr.DeviceTypeClass3 == "" || (opr.OperatorEndDate != "" && (diffInDays < 0)))
      {
      return 0;
    }
    else 
    {
      return 1;
    }
  }

  totalDevicesCount(event:any) {
    this.totalDeviceCount = Number(this.operatorForm.controls.DeviceTypeClass1.value) + Number(this.operatorForm.controls.DeviceTypeClass2.value) + Number(this.operatorForm.controls.DeviceTypeClass3.value)
    //console.log("TOTAL DVICE COUNT", this.totalDeviceCount)
  }

}

