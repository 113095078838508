import { Component, OnInit, HostListener } from '@angular/core';
import { FileApprovalService } from '../services/file-approval.service'
import { FileHistoryService } from '../services/file-history.service'
import { OperatorService } from '../services/operator.service';

@Component({
  selector: 'app-file-approval',
  templateUrl: './file-approval.component.html',
  styleUrls: ['./file-approval.component.scss']
})
export class FileApprovalComponent implements OnInit {
  public fileHistory: [] = [];
  public onError: any;
  public spiner: boolean = false;
  public searchText: any;
  public isShow: boolean = false;
  public showActiveDiv: { [key: number]: boolean } = {};
  public selectedObj: {} = {};
  public showPopup: boolean = false;
  public approvedElement: boolean = false;
  public disableLink: boolean = true;
  public key: string = '';
  public reverse: boolean = false;
  Downloadingspiner: boolean = false;
  Downloadingsuccess: any;
  isToggled = false;
  errormsg = '';
  ErrorsPopupModelDispay: boolean = false;
  promptmessage: string = "";
  constructor(
    private fileApprovalService: FileApprovalService,
    private fileHistoryService: FileHistoryService,
    private os: OperatorService
  ) {
    this.os.getCurrentUser();
  }

  ngOnInit(): void {
    this.GetDetails();
  }

  sort(key: any) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  sortByDate() {
    if (!this.isToggled) {
      this.fileHistory.sort(function (a: any, b: any) {
        return <any>new Date(a.UPLOADED_DTTM) - <any>new Date(b.UPLOADED_DTTM);
      });
    } else {
      this.fileHistory.sort(function (a: any, b: any) {
        return <any>new Date(b.UPLOADED_DTTM) - <any>new Date(a.UPLOADED_DTTM);
      });
    }
    this.isToggled = !this.isToggled;
  }

  sessionRenewal() {
    this.os.sessionTokenRenewal().subscribe((res: any) => {
      if (res && res.serviceResponseHeader.statusCode == 'REF-TOK-SUC-200') {
        console.log("session renewal respose", res)
        this.GetDetails();
      }
      else {
        console.log("Session timeout / LOGOUT");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
    }, (error) => {
      console.log("Error occured: " + error);
      this.ErrorsPopupModelDispay = true;
      this.promptmessage = "Session has expired, Please re-login";
    })
  }

  closepopup() {
    this.ErrorsPopupModelDispay = false;
    localStorage.clear();
    window.location.href = this.os.getDslMonitoringUrl();
  }

  GetDetails() {
    let formData = new FormData();
    this.spiner = true;
    this.fileHistoryService.getAllFiles().subscribe((response) => {
      this.fileHistory = response;
      if (this.fileHistory != null) {
        this.fileHistory.sort(function (a: any, b: any) {
          return <any>new Date(a.UPLOADED_DTTM) - <any>new Date(b.UPLOADED_DTTM);
        });
        this.spiner = false;
        //console.log("VALUE RECEIVED:", this.fileHistory);
      }
      else {
        this.spiner = false;
        this.errormsg = "No Strike Data Found";
      }
    }, (error) => {
      console.log(error);
      if (error.error.serviceResponseHeader && (error.error.serviceResponseHeader.statusCode == 'UNAUTHORIZED-ERR' || error.error.serviceResponseHeader.statusCode == 'VAL-TOK-ERR-105')) {
        this.sessionRenewal();
      }
      else {
        console.log("Session timeout / LOGOUT");
        this.ErrorsPopupModelDispay = true;
        this.promptmessage = "Session has expired, Please re-login";
      }
      //this.onError(error);
    })
  }

  closePopup(event: any) {
    this.showPopup = false;
    this.Downloadingspiner = false;
    event.stopPropagation()
    this.disableLink = true;
  }
  // onSelect(event:any,index:number) { // You get the Id of the selected item here
  //   //(this.showActiveDiv[index] = false)?(this.showActiveDiv[index] = true)
  //   if(this.showActiveDiv[index] = false){
  //     this.showActiveDiv[index] = true;
  //     event.stopPropagation()
  //   }
  // }
  onSelect(event: any, index: any) {
    this.showActiveDiv[index] = true;
    for (let i = 0; i <= this.fileHistory.length; i++) {
      let ind: any;
      if (this.fileHistory[i]) {
        ind = this.fileHistory[i]["FILE_NAME"];
      }
      if (ind != index) {
        this.showActiveDiv[ind] = false;
      }
      // setTimeout(() => {
      //   return this.showActiveDiv[ind] = false;
      // }, 4000);
    }
    //event.preventDefault()
    event.stopPropagation()
  }
  @HostListener('document:click', ['$event']) onDocumentClick(index: any, event: any) {
    this.showPopup = false;
    //this.showActiveDiv[index] = false;
    //this.disableLink = true;
    this.onSelect(event, index)
  }

  download(event: any, selectedItem: any, index: number) {
    this.selectedObj = {
      "FILE_NAME": selectedItem.FILE_NAME
      //"STATUS": "Approved"
    }
    this.downloadFile(selectedItem.FILE_NAME, selectedItem.UPLOADED_DTTM);
    console.log("Filedownloaded");

    this.showActiveDiv[index] = false;
  }

  approved(event: any, selectedItem: any, index: number) {
    //this.disableLink = false;
    let currentUser = localStorage.getItem('currentUser');
    let currentUserEmailid = localStorage.getItem('currentUserEmailid');
    if (selectedItem.STATUS === "Pending") {
      this.selectedObj = {
        "FILE_NAME": selectedItem.FILE_NAME,
        "UPLOADED_BY": selectedItem.UPLOADED_BY,
        "APPROVED_BY": currentUser,
        "APPROVED_USER_EMAIL": currentUserEmailid,
        "STATUS": "Approved",
        "UPLOADED_DTTM": selectedItem.UPLOADED_DTTM,
        "USER_EMAILID": currentUserEmailid,
        "UPLOADED_USR_EMAILID": selectedItem.UPLOADED_USR_EMAILID
      }
    }
    this.showActiveDiv[index] = false;
    if (selectedItem.UPLOADED_BY == currentUser) {
      this.disableLink = false;
    } else {
      this.disableLink = true;
      this.updateDetails(this.selectedObj)
      this.insertInToDB(this.selectedObj)
      this.approvedElement = true;
      this.os.sendUploadFileEmailNotification(this.selectedObj, 'approveFile').subscribe((response) => {
      }, (error) => {
        console.log("Error occured: " + error);
      })
    }
    //this.showPopup = true;
    //event.stopPropagation()
  }
  rejected(event: any, selectedItem: any, index: number) {
    //this.disableLink = true;
    let currentUser = localStorage.getItem('currentUser');
    let currentUserEmailid = localStorage.getItem('currentUserEmailid');
    if (selectedItem.STATUS === "Pending") {
      this.selectedObj = {
        "FILE_NAME": selectedItem.FILE_NAME,
        "UPLOADED_BY": selectedItem.UPLOADED_BY,
        "APPROVED_BY": currentUser,
        "APPROVED_USER_EMAIL": currentUserEmailid,
        "STATUS": "Rejected",
        "UPLOADED_DTTM": selectedItem.UPLOADED_DTTM,
        "USER_EMAILID": currentUserEmailid,
        "UPLOADED_USR_EMAILID": selectedItem.UPLOADED_USR_EMAILID
      }
    }
    if (selectedItem.UPLOADED_BY == currentUser) {
      this.disableLink = false;
    } else {
      this.disableLink = true;
      this.updateDetails(this.selectedObj)
      //this.showPopup = true;
      this.approvedElement = false;
      this.showActiveDiv[index] = false;
      this.os.sendUploadFileEmailNotification(this.selectedObj, 'rejectFile').subscribe((response) => {
      }, (error) => {
        console.log("Error occured: " + error);
      })
    }
    event.stopPropagation()
  }

  updateDetails(obj: object) {
    this.fileApprovalService.updateFiles(obj).subscribe(
      (response) => {
        this.showPopup = true;
        this.GetDetails()
      },
      (error) => {
        console.log("Error occured: " + error);
        this.showPopup = false;
      }
    )
  }

  insertInToDB(obj: object) {
    this.fileApprovalService.insertRecords(obj).subscribe(
      (response) => {
        //console.log("Data inserted: ", response);
        //this.showPopup = true;
        //this.GetDetails()
      },
      (error) => {
        console.log("Error occured: " + error);
        //this.showPopup = false;
      }
    )
  }


  downloadFile(obj: string, uploadeddate: string) {
    let anchor = '<a/>';
    this.fileApprovalService.S3downloadFile(obj, uploadeddate).subscribe(response => {
      if (response == "Not Exists") {
        console.info('no file downloaded');
        //  return false;
        this.Downloadingspiner = true;
      }
      else {
        this.Downloadingspiner = false;
        const a = document.createElement('a');
        var blob = new Blob([decodeURIComponent(encodeURI(response))], {
          type: "application/vnd.ms-excel;charset=utf-8;"
        });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = obj;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        // return true;
      }
    }, (error) => {
      console.log('Error downloading the file');
      // return false;
      this.Downloadingspiner = true;
    },
    );
    //this.Downloadingspiner = false;
  }
}
